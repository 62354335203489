<ion-header>
  <ion-toolbar [style.--background]="topic?.color" style="color: white;">
    <ion-title>
      Thema bearbeiten
    </ion-title>

    <ion-buttons slot="end">
      <ion-button (click)=popoverController.dismiss()>
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">

<ng-container *ngIf="topic">
  <ion-input label="Name"
             label-placement="stacked"
             fill="outline"
             [(ngModel)]="topic.name">
  </ion-input>
  <!--  <ion-input class="ion-margin-top"-->
  <!--             label="Farbe"-->
  <!--             label-placement="stacked"-->
  <!--             fill="outline"-->
  <!--             [(ngModel)]="topic.color">-->
  <!--  </ion-input>-->

  <div class="color-picker my-15">
    <div *ngFor="let color of colors"
         class="color-field"
         [class.color-field--active]="topic.color === color"
         [style.--color-field-color]="color"
         (click)="topic.color = color">
    </div>
  </div>

<!--  <ion-input class="ion-margin-top"-->
<!--             label="Reihenfolge"-->
<!--             label-placement="stacked"-->
<!--             fill="outline"-->
<!--             type="number"-->
<!--             [(ngModel)]="topic.order">-->
<!--  </ion-input>-->

  <ion-item>
    <ion-toggle [checked]="topic.tracker?.range"
                (ionChange)="toggleRangeTracker($event, topic)">
      Tracker
    </ion-toggle>
  </ion-item>

  <ion-row *ngIf="topic.tracker?.range"
           class="ion-margin-start">
    <ion-col size="auto">
      Zielwert:
    </ion-col>
    <ion-col size="auto">
      <div style="width: 150px;">
        <ion-segment mode="ios"
                     [value]="topic.tracker?.rangeTarget"
                     (ionChange)="updateRangeTarget($event, topic)">
          <ion-segment-button value="LOW">Niedrig</ion-segment-button>
          <ion-segment-button value="HIGH">Hoch</ion-segment-button>
        </ion-segment>
      </div>
    </ion-col>
  </ion-row>


</ng-container>

<ion-row class="ion-justify-content-between">
  <ion-col size="auto">
<!--    <ion-button color="danger"-->
<!--                size="small"-->
<!--                fill="outline"-->
<!--                (click)="deleteTopic()">-->
<!--      <ion-icon name="trash-outline"></ion-icon>-->
<!--    </ion-button>-->
  </ion-col>
  <ion-col size="auto">
    <ion-button color="success"
                size="small"
                fill="outline"
                (click)="saveAndClose()">
      Speichern
    </ion-button>
  </ion-col>
</ion-row>

</ion-content>


<!--<ion-footer>-->
<!--  <ion-row class="ion-justify-content-between">-->
<!--    <ion-col size="auto">-->
<!--      <ion-button color="danger"-->
<!--                  (click)="deleteTopic()">-->
<!--        Thema löschen-->
<!--      </ion-button>-->
<!--    </ion-col>-->
<!--    <ion-col size="auto">-->
<!--      <ion-button color="success"-->
<!--                  (click)="saveAndClose()">-->
<!--        Speichern-->
<!--      </ion-button>-->
<!--    </ion-col>-->
<!--  </ion-row>-->
<!--</ion-footer>-->
