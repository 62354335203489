import {Component, Input, isDevMode, OnInit} from '@angular/core';
import {AudioService} from '../../../services/audio.service';
import {ToastController} from '@ionic/angular';
import {NotificationService} from '../../../services/notification.service';
import {windowDebug} from '../../../tools/debug';
import {TimerService} from './timer.service';
import {Observable, tap} from 'rxjs';
import {v4 as uuidv4} from 'uuid';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit {
  @Input() name?: string;
  timerTimeMin = 45;

  timerId = uuidv4();
  timerSeconds$: Observable<number>;

  get timerActive() {
    return this.timerService.activeTimerId === this.timerId;
  }

  constructor(private audio: AudioService,
              private toastController: ToastController,
              private timerService: TimerService,
              private notificationService: NotificationService) {

    windowDebug('TimerComponent', this);

    if (isDevMode()) {
      this.timerTimeMin = 1 / 12;
      // this.timerRemaining = 5;
    }
    this.timerSeconds$ = this.timerService
      .registerTimer(this.timerId, this.timerTimeMin * 60)
      .pipe(tap(remainingSeconds => {
        if (remainingSeconds === 0) {
          this.notifyTimerUp();
        }
      }));
  }

  ngOnInit() {
  }


  toggleTimer() {
    this.timerService.toggleTimer(this.timerId);
  }

  resetTimer() {
    this.timerService.resetTimer(this.timerId, this.timerTimeMin * 60)
  }

  notifyTimerUp() {
    this.audio.plop();
    this.notificationService.notify(
      `${this.name} Timer abgelaufen!'`,
      'Halte jetzt deine Erfolge fest!',
      this.timerId
    )
    this.toastController.create({
      header: 'Timer abgelaufen',
      buttons: [
        {
          icon: 'stop-circle-outline',
          text: 'Timer stoppen',
          role: 'stopTimer',
          handler: () => this.toggleTimer()
        }
      ]
    }).then(toast => toast.present())
  }

}
