import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {enableElfProdMode} from '@ngneat/elf';
import {devTools} from '@ngneat/elf-devtools';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {initSentry} from './sentry';
import {initDayJs} from './day-js';

initDayJs();

if (environment.production) {
  initSentry();
  enableProdMode();
  enableElfProdMode()
} else {
  devTools()
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
