<ng-container *ngIf="imageEntry">
<!--  <ion-header>-->
<!--    <div class="container">-->
<!--      <ion-toolbar>-->
<!--        <ion-title>-->
<!--          Bild: {{imageEntry.name}}-->
<!--        </ion-title>-->
<!--      </ion-toolbar>-->
<!--    </div>-->
<!--  </ion-header>-->

  <ion-content (click)="closeViewer()">
    <ion-button class="image-viewer-close"
                fill="outline"
                color="dark"
                (click)="closeViewer()">
      <ion-icon slot="icon-only" name="close"></ion-icon>
    </ion-button>

    <div class="image-viewer-container">
      <img class="image-viewer-image" [src]="imageEntry.data | fileToImage">
    </div>
  </ion-content>
</ng-container>

