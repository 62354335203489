import * as dayjs from 'dayjs';
import {Dc3Entry} from '../data/entry/entry';
import {where} from '@angular/fire/firestore';

export const dateFormat = 'YYYY-MM-DD';

export const dateRange = (from: number, to: number, step: dayjs.ManipulateType, format: string) => {
  const list = []
  let current = dayjs(from)
  while (!current.isSame(dayjs(to).add(1, step), step)) {
    list.push(current.format(format))
    current = current.add(1, step);
  }
  return list
}

export const sortByDate = (entries: Dc3Entry[]) => {
  entries = [...entries];
  return entries.sort((a, b) => {
    const unixA = new Date(a.date).valueOf();
    const unixB = new Date(b.date).valueOf();
    return unixB - unixA
  });
}

//todo make this immutable! ...setDay(): TimeFrame
export interface CustomTimeFrame {
  startDate?: number;
  endDate?: number;
}

export class TimeFrame {
  // date: Date = new Date();
  // timeFrame: 'day' | 'week' = 'day';
  public custom?: CustomTimeFrame;

  get dateDay() {
    return dayjs(this.date).valueOf();
  }

  get startDate() {
    if (this.custom && this.custom.startDate) {
      return this.custom.startDate;
    }
    return dayjs(this.date).startOf(this.unit).valueOf();
  }

  get endDate() {
    if (this.custom && this.custom.endDate) {
      return this.custom.endDate;
    }
    return dayjs(this.date).endOf(this.unit).valueOf();
  }

  get days() {
    return dateRange(
      this.startDate,
      this.endDate,
      'day',
      dateFormat
    )
  }

  queryConstraints(dateProperty: string = 'date') {
    return [
      where(dateProperty, '>=', this.startDate),
      where(dateProperty, '<=', this.endDate)
    ];
  }

  constructor(public date: Date = new Date(),
              public unit: 'day' | 'week' | 'month' = 'week') {
  }

  isBetween(date: number) {
    return dayjs(date).isBetween(this.startDate, this.endDate, 'day', '[]');
  }

  setCustom(customTimeFrame: CustomTimeFrame) {
    //this.custom = customTimeFrame;
    const newTimeFrame = new TimeFrame(this.date, this.unit);
    newTimeFrame.custom = customTimeFrame;
    return newTimeFrame;
  }

  setCustomStartDate(date: number) {
    return this.setCustom({startDate: date})
  }

  setCustomEndDate(date: number) {
    return this.setCustom({startDate: date})
  }
}
