<ion-header>
  <ion-toolbar>
    <ion-title>Thema für Zusammenfassung auswählen</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">

  <app-styled-topic *ngFor="let topic of topics$ | async"
                    class="topic-outline topic-button mr-10 mb-10"
                    [topic]="topic"
                    (click)="topicClicked(topic)">
  </app-styled-topic>

</ion-content>
