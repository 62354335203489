import {Component, OnInit} from '@angular/core';
import {ImageEntry} from '../../data/images/image-db';
import {ModalController} from '@ionic/angular';


@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit {
  imageEntry: ImageEntry | undefined;

  constructor(private modalController: ModalController) {
  }

  ngOnInit() {
  }

  closeViewer() {
    this.modalController.dismiss();
  }
}
