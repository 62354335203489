export const environment = {
  firebase: {
    projectId: 'dc3-alpha',
    appId: '1:1018785083154:web:c7a4ae90c14ddda0088dcb',
    storageBucket: 'dc3-alpha.appspot.com',
    locationId: 'europe-west3',
    apiKey: 'AIzaSyDaADH1wMOb1O7X-r6GoAPtbAt6hf6yOD4',
    authDomain: 'dc3-alpha.firebaseapp.com',
    messagingSenderId: '1018785083154',
    measurementId: 'G-KGTYJBRDHK',
  },
  useEmulators: false,
  production: true,

  syncUpDebounceMilliseconds: 0,
  elfPersistence: false,
};
