<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button icon="chevron-back-outline"
                       [defaultHref]="defaultHref">
      </ion-back-button>
    </ion-buttons>

    <ion-title>
      <div class="logo">
        <div class="logo-text">
          dc3
        </div>
      </div>
      <span class="text-header">
        <ng-content></ng-content>
      </span>
    </ion-title>

    <ion-buttons slot="end" class="ion-padding-end header-buttons">
      <ion-button routerLink="/today"
                  routerLinkActive="button--active">
        mein tag
      </ion-button>

      <ion-button routerLink="/plan"
                  routerLinkActive="button--active">
        planen
      </ion-button>

      <div class="spacer"></div>

      <ion-button routerLink="/understand"
                  routerLinkActive="button--active">
        verstehen
      </ion-button>

      <ion-button routerLink="/topics"
                  routerLinkActive="button--active">
        blog
      </ion-button>

      <div class="spacer"></div>

      <ion-button *ngIf="user$ | async as user"
                  (click)="showAccountPopover($event)">
        {{user.email}}
      </ion-button>
      <ion-button (click)="audio.toggleAudio()">
        <ion-icon *ngIf="audio.enabled" slot="icon-only" name="volume-high-outline"></ion-icon>
        <ion-icon *ngIf="!audio.enabled" name="volume-mute-outline"></ion-icon>
      </ion-button>
      <!--  <ion-button>-->
      <!--    <ion-icon name="settings-outline"></ion-icon>-->
      <!--  </ion-button>-->
    </ion-buttons>
  </ion-toolbar>
</ion-header>
