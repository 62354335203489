<ion-content>

  <div class="login-card simple-card ion-margin-top">
    <h2 class="login-label text-header mt-0 mb-20">
      dc3-alpha Login
    </h2>
    <form *ngIf="form" [formGroup]="form" (keyup.enter)="login()">
      <ion-input class="ion-margin-top"
                 label="E-Mail"
                 label-placement="stacked"
                 fill="outline"
                 formControlName="email">
      </ion-input>
      <ion-input class="ion-margin-top"
                 label="Passwort"
                 label-placement="stacked"
                 type="password"
                 fill="outline"
                 formControlName="password">
      </ion-input>
      <ion-button class="ion-margin-top"
                  [disabled]="form.invalid"
                  (click)="login()">
        Anmelden
      </ion-button>

      <p *ngIf="loginError">
        <ion-text color="danger">
          Anmeldung war nicht möglich. Wahrscheinlich stimmen die eingegebenen Daten nicht.
        </ion-text>
      </p>

    </form>

  </div>
</ion-content>
