<app-header-nav>
  Mein Tag:
  {{ date$ | async | date: 'dd.MM.YYYY'}}
  <ion-badge *ngIf="dateIsYesterday$ | async"
             class="ml-10"
             style="display: inline-block;">
    gestern
  </ion-badge>
</app-header-nav>

<ion-content>

  <ion-row>
    <ion-col size="3">
      <div class="day-index ion-padding">
        <app-day-index-block *ngFor="let indexBlock of index$ | async"
                             [indexBlock]="indexBlock"
                             [focusedTopicId]="focusedTopicId"
                             (topicClick)=scrollToAndFocusTopic($event)
                             (topicReorder)="updateTopics($event)">
        </app-day-index-block>
      </div>
    </ion-col>

    <ion-col size="6">
      <div class="container">
        <ng-container *ngIf="planningEntries$ | async as planningEntries">
          <div *ngIf="planningEntries.length > 0">
            <h2 class="ion-text-center">
              <ion-icon name="calendar-outline"></ion-icon>
              Geplant
            </h2>
            <app-entry-editor *ngFor="let vm of planningEntries; trackBy: trackPlanningEntries"
                              [entry]="vm.entry"
                              [isFocused]="vm.entry.topicId === focusedTopicId"
                              [planning]="vm.planning"
                              (editorFocused)="onEditorFocused($event)">
            </app-entry-editor>
          </div>
        </ng-container>

        <h2 class="ion-text-center">
          <ion-icon name="bar-chart-outline"></ion-icon>
          Tracker
        </h2>
        <app-entry-editor *ngFor="let entry of trackerEntries$ | async; trackBy: trackEntries"
                          [entry]="entry"
                          [isFocused]="entry.topicId === focusedTopicId"
                          (editorFocused)="onEditorFocused($event)">
        </app-entry-editor>

        <ng-container *ngIf="pinnedEntries$ | async as pinnedEntries">
          <ng-container *ngIf="pinnedEntries.length">
            <h2 class="ion-text-center">
              <ion-icon name="bookmark-outline"></ion-icon>
              Lesezeichen
            </h2>
            <app-entry-editor *ngFor="let entry of pinnedEntries; trackBy: trackEntries"
                              [entry]="entry"
                              [isFocused]="entry.topicId === focusedTopicId"
                              (editorFocused)="onEditorFocused($event)">
            </app-entry-editor>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="otherEntries$ | async as otherEntries">
          <ng-container *ngIf="otherEntries.length">
            <h2 class="ion-text-center">
              <ion-icon name="bulb-outline"></ion-icon>
              Sonstige
            </h2>
            <app-entry-editor *ngFor="let entry of otherEntries; trackBy: trackEntries"
                              [entry]="entry"
                              [isFocused]="entry.topicId === focusedTopicId"
                              (editorFocused)="onEditorFocused($event)">
            </app-entry-editor>
          </ng-container>
        </ng-container>


      </div>
    </ion-col>


    <ion-col size="3">
      <div class="fixed ion-padding">

        <p>Thema hinzufügen</p>

        <app-add-topic (addTopic)="addEntryWithNewTopic($event)"></app-add-topic>

        <div class="ion-margin-top">
          <app-styled-topic *ngFor="let topic of suggestedTopics$ | async"
                            class="topic-button topic-outline mb-10 mr-10"
                            [topic]="topic"
                            (click)="addEntry(topic)">
          </app-styled-topic>
        </div>

        <!--        <h2>Moodtracking</h2>-->
        <!--        <ion-row>-->
        <!--          <ion-col size="12"-->
        <!--                   *ngFor="let entry of trackerEntries$ | async; trackBy: trackEntries">-->
        <!--            <ion-row>-->
        <!--              <ion-col size="auto">-->
        <!--                <ion-button fill="outline" style="width: 90px;"-->
        <!--                            (click)="changeToFullEntry(entry)">-->
        <!--                  {{entry.topic.name}}-->
        <!--                </ion-button>-->
        <!--              </ion-col>-->
        <!--              <ion-col>-->
        <!--                <ion-range class="ion-no-padding"-->
        <!--                           [value]="entry.value"-->
        <!--                           step="0.05"-->
        <!--                           [snaps]="true"-->
        <!--                           min="0"-->
        <!--                           max="1"-->
        <!--                           [pin]="true"-->
        <!--                           [pinFormatter]="pinFormatter"-->
        <!--                           [color]="entry | rangeTrackerColor"-->
        <!--                           (ionInput)="plop()"-->
        <!--                           (ionChange)="editValueByEvent($event, entry)">-->
        <!--                </ion-range>-->
        <!--              </ion-col>-->
        <!--            </ion-row>-->
        <!--          </ion-col>-->
        <!--        </ion-row>-->
      </div>
    </ion-col>


  </ion-row>
</ion-content>
