import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent  implements OnInit {
  alphaKey?: string;

  constructor(private route: ActivatedRoute) {
    this.alphaKey = route.snapshot.queryParams['alphaKey'];
  }

  ngOnInit() {}

}
