import {Injectable} from '@angular/core';
import {SwPush} from '@angular/service-worker';

@Injectable({providedIn: 'root'})
export class NotificationService {
  permissionGranted = false;
  serviceWorkerRegistration?: ServiceWorkerRegistration;

  constructor(private swPush: SwPush) {
    (window as any)['notificationService'] = this;

    if (Notification.permission === 'granted') {
      this.permissionGranted = true;
      navigator.serviceWorker.ready.then((result) => {
        this.serviceWorkerRegistration = result;
        console.log('service worker registered', result);
      });
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then(permission => {
        this.permissionGranted = permission === 'granted';
      })
    }

    this.swPush.notificationClicks.subscribe(result => {
      console.log('notificationClicks', result);

    })
  }

  notify(title: string, message: string, tag: string) {
    const url = location.origin + '/today'

    if (this.permissionGranted) {
      if (this.serviceWorkerRegistration) {
        this.serviceWorkerRegistration.showNotification(title, {
          icon: 'assets/icons/icon-144x144.png',
          body: message,
          tag: tag,
          requireInteraction: true,
          actions: [{
            icon: 'svg/stop-outline.svg',
            action: 'stopTimer',
            title: 'stop'
          }],
          data: {
            onActionClick: {
              default: {
                operation: 'navigateLastFocusedOrOpen',
                url: url
              }
            }
          }
        });
      } else {
        new Notification(title, {
          icon: 'assets/icons/icon-144x144.png',
          body: message
        });
      }

    }
  }
}
