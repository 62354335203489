<ion-row class="ion-align-items-center">
  <ion-col size="auto">
    <ion-input fill="outline"
               size="small"
               placeholder="Neues Thema..."
               [(ngModel)]="newTopic"
               (keyup.enter)="emitTopic()">
    </ion-input>
  </ion-col>
  <ion-col>
    <ion-button color="success"
                [disabled]="!newTopic"
                (click)="emitTopic()">
      <ion-icon slot="icon-only" name="add-outline"></ion-icon>
    </ion-button>
  </ion-col>
</ion-row>
