import * as Sentry from '@sentry/angular-ivy';
import {APP_INITIALIZER, ErrorHandler} from '@angular/core';
import {Router} from '@angular/router';


export const initSentry = () => {
  Sentry.init({
    dsn: 'https://c660a45ecc85ebeb1207d15d0a369a90@o4505727285460992.ingest.sentry.io/4505727289982976',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', 'https:yourserver.io/api/'],
        routingInstrumentation: Sentry.routingInstrumentation
      }),
      new Sentry.Replay()
    ],
    release: '0.1.0',
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  console.log('sentry initialized')
}

export const sentryProviders = [
  {
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler({
      // showDialog: true
      // dialogOptions: {user: {name: '', email: ''}}
    })
  }, {
    provide: Sentry.TraceService,
    deps: [Router]
  },
  {
    provide: APP_INITIALIZER,
    useFactory: () => () => {
    },
    deps: [Sentry.TraceService],
    multi: true
  }
];
