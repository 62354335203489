import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ItemReorderEventDetail} from '@ionic/angular';

import {Dc3Entry} from '../../../data/entry/entry';
import {Dc3Topic} from '../../../data/topic/topic';

export interface IndexBlockViewModel {
  title: string;
  icon: string;
  entries: Dc3Entry[]
}


@Component({
  selector: 'app-day-index-block',
  templateUrl: './day-index-block.component.html',
  styleUrls: ['./day-index-block.component.scss']
})
export class DayIndexBlockComponent implements OnInit {
  @Input() indexBlock?: IndexBlockViewModel;
  @Input() focusedTopicId?: string;

  @Output() topicClick = new EventEmitter<string>();
  @Output() topicReorder = new EventEmitter<Dc3Topic[]>();

  constructor() {
  }

  ngOnInit() {
  }

  handleReorder(ev: CustomEvent<ItemReorderEventDetail>) {
    const oldOrder = [...this.indexBlock!.entries.map(x => x.topic)];
    const newOrder = [...ev.detail.complete([...oldOrder]) as Dc3Topic[]];

    const updatedTopics: Dc3Topic[] = newOrder
      .map((topic, index) => {
        // console.log('mapping', topc)
        return {
          ...topic,
          order: oldOrder[index].order
        }
      });

    this.topicReorder.emit(updatedTopics);
  }
}
