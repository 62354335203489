import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Dc3Topic} from '../../../data/topic/topic';

@Component({
  selector: 'app-topic-block',
  templateUrl: './topic-block.component.html',
  styleUrls: ['./topic-block.component.scss']
})
export class TopicBlockComponent implements OnInit {
  @Input() icon?: string;
  @Input() header?: string;

  @Input() topics: Dc3Topic[] | undefined | null;
  @Input() topicButton = false;
  @Input() selectedTopicIds: string[] | undefined | null;

  @Output() topicClick = new EventEmitter<Dc3Topic>();

  constructor() {
  }

  ngOnInit() {
  }

}
