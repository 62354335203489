import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {add} from 'ionicons/icons';

@Component({
  selector: 'app-add-topic',
  templateUrl: './add-topic.component.html',
  styleUrls: ['./add-topic.component.scss']
})
export class AddTopicComponent implements OnInit {
  newTopic = '';
  @Output() addTopic = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit() {
  }

  protected readonly add = add;

  emitTopic() {
    if (this.newTopic) {
      this.addTopic.emit(this.newTopic)
      this.newTopic = '';
    }
  }
}
