import {Component, Input, OnInit} from '@angular/core';
import {Dc3Topic} from '../../../data/topic/topic';
import {TopicRepository} from '../../../data/topic/topic.repository';
import {AlertController, ModalController, PopoverController} from '@ionic/angular';
import {EntryRepository} from '../../../data/entry/entry.repository';
import {take} from 'rxjs';
import {defaultTopicColors} from '../../../tools/colors';

@Component({
  selector: 'app-topic-edit',
  templateUrl: './topic-edit.component.html',
  styleUrls: ['./topic-edit.component.scss']
})
export class TopicEditComponent implements OnInit {
  @Input() topic: Dc3Topic | undefined;

  colors = defaultTopicColors;

  constructor(private alertController: AlertController,
              public popoverController: PopoverController,
              private topicRepository: TopicRepository,
              private entryRepository: EntryRepository) {
  }

  ngOnInit() {
  }

  toggleRangeTracker($event: any, topic: Dc3Topic) {
    if (!topic.tracker) {
      topic.tracker = {
        range: true,
        rangeTarget: 'HIGH'
      };
    } else {
      topic.tracker.range = !topic.tracker.range;
    }
  }

  updateRangeTarget(event: any, topic: Dc3Topic) {
    if (topic.tracker) {
      topic.tracker.rangeTarget = event.detail.value;
    }
  }

  deleteTopic() {
    if (this.topic) {
      this.entryRepository.getEntriesByTopic$(this.topic)
        .pipe(take(1)).subscribe(entriesToDelete => {
        this.alertController.create({
          header: 'Topic wirklich löschen?',
          message: `Möchten sie wirklich das Thema '${this.topic?.name}' mit allen ${entriesToDelete.length} Einträgen und Trackings löschen?`,
          buttons: ['Abbrechen', {
            text: 'Löschen',
            handler: () => {
              if (this.topic) {
                this.entryRepository.deleteEntries(entriesToDelete);
                this.topicRepository.deleteTopics([this.topic]);
                this.popoverController.dismiss();
              }
            }
          }]
        }).then(alert => alert.present());
      });
    }
  }

  saveAndClose() {
    if (this.topic) {
      console.log(this.topic);
      this.topicRepository.upsertTopics([this.topic]);
      this.popoverController.dismiss();
    }
  }
}
