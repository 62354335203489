import {Component, OnInit} from '@angular/core';
import {Auth, user} from '@angular/fire/auth';
import {Router} from '@angular/router';
import {getRegistry} from '@ngneat/elf';
import {imageDb} from '../../data/images/image-db';
import {ModalController, PopoverController} from '@ionic/angular';
import {FirebaseSyncService} from '../../data/firebase-sync.service';
import {EntryRepository} from '../../data/entry/entry.repository';
import {TopicRepository} from '../../data/topic/topic.repository';
import {PlanningRepository} from '../../data/planning/planning.repository';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  user

  constructor(private auth: Auth,
              private router: Router,
              private modalController: ModalController,
              private popoverController: PopoverController,
              private firebaseSyncService: FirebaseSyncService,
              private entryRepository: EntryRepository,
              private topicRepository: TopicRepository,
              private planningRepository: PlanningRepository) {
    (window as any)['accountComponent'] = this;

    this.user = user(this.auth);
  }

  ngOnInit() {
  }

  async logout() {
    // localStorage.clear();
    await this.popoverController.dismiss();



    this.firebaseSyncService.reset();
    await this.auth.signOut();

    await this.router.navigate(['/login']);

    this.planningRepository.reset();
    this.entryRepository.reset();
    this.topicRepository.reset();

    // getRegistry().forEach(store => store.reset());
    await imageDb.images.clear();
  }
}
