import {Component, inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Auth, signInWithEmailAndPassword} from '@angular/fire/auth';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  private auth = inject(Auth)
  form: FormGroup | undefined;

  loginError = false;

  constructor(private fb: FormBuilder,
              private router: Router) {
    (window as any)['loginComponent'] = this;
  }

  testError() {
    const testObj: any = {};
    testObj.blob();
    console.log(testObj.blob.dobbo);
  }

  ngOnInit() {
    this.form = this.fb.group({
      email: ['', Validators.email],
      password: ['', Validators.required]
    });

    if (!environment.production) {
      this.form.patchValue({
        email: 'dev2@rene.tk',
        password: '12345678'
      })
    }
  }

  async login() {
    if (this.form && this.form.valid) {
      const formValue = this.form?.value;
      try {
        const userCredential = await signInWithEmailAndPassword(
          this.auth,
          formValue.email,
          formValue.password
        );
        // console.log('loggedIn', userCredential);
        this.router.navigateByUrl('today')
      } catch (err) {
        console.log(err);
        this.loginError = true;
      }
    }
  }
}
