import {Component, inject, OnInit} from '@angular/core';
import {TopicRepository} from '../../../data/topic/topic.repository';
import {Dc3Topic} from '../../../data/topic/topic';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-choose-topic',
  templateUrl: './choose-topic.component.html',
  styleUrls: ['./choose-topic.component.scss'],
})
export class ChooseTopicComponent  implements OnInit {
  modalController = inject(ModalController);
  topicRepository = inject(TopicRepository);
  topics$ = this.topicRepository.topics$

  ngOnInit() {}

  topicClicked(topic: Dc3Topic) {
    this.modalController.dismiss(
      topic,
      'topicChosen'
    )
  }
}
