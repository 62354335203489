<!--<ion-header>-->
<!--  <ion-toolbar>-->
<!--    <ion-title>-->
<!--      Account-->
<!--    </ion-title>-->
<!--  </ion-toolbar>-->
<!--</ion-header>-->

<ion-content>

  <ion-button color="danger"
              (click)="logout()">
    Abmelden
  </ion-button>
</ion-content>
