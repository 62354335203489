import {Component, inject, OnInit} from '@angular/core';
import {Auth, createUserWithEmailAndPassword} from '@angular/fire/auth';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {collection, deleteDoc, doc, Firestore, getDoc, setDoc} from '@angular/fire/firestore';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  private auth = inject(Auth);
  private firestore = inject(Firestore);

  form: FormGroup | undefined;
  registerError = false;
  alphaKeyError = false;

  constructor(private route: ActivatedRoute,
              private fb: FormBuilder,
              private router: Router) {
  }

  ngOnInit() {
    const key = this.route.snapshot.params['key'];

    this.form = this.fb.group({
      alphaKey: [key, Validators.required],
      email: ['', Validators.email],
      password: ['', Validators.required]
    });

    if (!environment.production) {
      this.form.patchValue({
        email: 'dev3@rene.tk',
        password: '12345678'
      })
    }
  }

  async register() {
    this.registerError = false;
    this.alphaKeyError = false;
    if (this.form) {
      const formValue = this.form.value;
      try {

        const keyRef = doc(
          this.firestore,
          'alphaKeys',
          formValue.alphaKey
        )
        const keyDoc = await getDoc(keyRef);

        if (keyDoc.exists()) {

          console.log('key exists', keyDoc.data());

          const registration = await createUserWithEmailAndPassword(
            this.auth,
            formValue.email,
            formValue.password
          );

          const userRef = doc(
            this.firestore,
            'users',
            registration.user.uid
          )

          await setDoc(userRef, keyDoc.data())
          await deleteDoc(keyRef);
          await this.router.navigate(['today']);
        } else {
          this.alphaKeyError = true;
        }


      } catch (e) {
        console.log(e);
        this.registerError = true;

      }
    }
  }
}
