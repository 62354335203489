import {Component, Input, OnInit} from '@angular/core';
import {Dc3Topic} from '../../../data/topic/topic';
import {PopoverController} from '@ionic/angular';
import {TopicEditComponent} from '../../topic/topic-edit/topic-edit.component';

@Component({
  selector: 'app-styled-topic',
  templateUrl: './styled-topic.component.html',
  styleUrls: ['./styled-topic.component.scss'],
})
export class StyledTopicComponent  implements OnInit {
  @Input() topic?: Dc3Topic;
  @Input() topicName?: string;

  constructor(private popoverController: PopoverController) { }

  ngOnInit() {}

  editPopover(ev: Event) {
    ev.preventDefault();
    console.log('edit?')
    this.popoverController.create({
      component: TopicEditComponent,
      componentProps: {topic: {...this.topic}},
      event: ev,
      cssClass: 'edit-topic-popover'
    }).then(popover => popover.present())
  }
}

