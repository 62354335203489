import Dexie, {Table} from 'dexie';
import {v4 as uuidv4} from 'uuid';

export interface ImageEntry {
  id: string;
  name: string;
  data: Blob;
  createdAt: Date;
  synced: number
}

export class ImageDb extends Dexie {
  images!: Table<ImageEntry, string>;

  constructor() {
    super('images');
    this.version(1).stores({
      images: 'id'
    });
    this.version(2).stores({
      images: 'id, synced'
    }).upgrade(trans => {
      return trans.table('images').toCollection().modify(image => {
        image.synced = 0
      })
    })
  }

  storeImage(name: string, data: Blob) {
    return this.images.put({
      id: uuidv4(),
      name,
      data,
      createdAt: new Date(),
      synced: 0
    });

  }
}

export const imageDb = new ImageDb();

