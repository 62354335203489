import {NgModule} from '@angular/core';
import {HeaderNavComponent} from './header-nav/header-nav.component';
import {RangeTrackerColorPipe} from './range-tracker-color.pipe';
import {SharedModule} from '../shared.module';
import {AddTopicComponent} from './add-topic/add-topic.component';
import {CalendarHeaderComponent} from './calendar-header/calendar-header.component';
import {CalendarCommonModule} from 'angular-calendar';
import {StyledTopicComponent} from './styled-topic/styled-topic.component';
import {TopicBlockComponent} from './topic-block/topic-block.component';
import {TimerComponent} from './timer/timer.component';
import {EntryEditorComponent} from './entry-editor/entry-editor.component';
import {ChooseTopicComponent} from './choose-topic/choose-topic.component';

const components = [
  HeaderNavComponent,
  AddTopicComponent,
  ChooseTopicComponent,
  EntryEditorComponent,
  CalendarHeaderComponent,
  RangeTrackerColorPipe,

  StyledTopicComponent,
  TopicBlockComponent,
  TimerComponent
]

@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    SharedModule,
    CalendarCommonModule
  ],
  exports: [
    ...components
  ]
})
export class ComponentsModule {}
