import {Pipe, PipeTransform} from '@angular/core';
import {Dc3Entry} from '../../data/entry/entry';
import {rangeTrackerColor} from '../../data/shared/range-tracker-color';

@Pipe({name: 'rangeTrackerColor'})
export class RangeTrackerColorPipe implements PipeTransform {
  transform(entry: Dc3Entry): string {
    const rangeTarget = entry.topic?.tracker?.rangeTarget;
    return rangeTrackerColor(rangeTarget, entry.value)
  }

}
