import {Injectable} from '@angular/core';

const audioEnabledStorageKey = 'audioEnabled';

@Injectable({providedIn: 'root'})
export class AudioService {
  private plopAudio = new Audio('/assets/audio/plop.mp3');
  enabled = false;

  constructor() {
    this.plopAudio.load();

    const audioEnabled = localStorage.getItem(audioEnabledStorageKey);
    if (audioEnabled && audioEnabled == 'on') {
      this.enabled = true;
    }
  }

  toggleAudio() {
    this.enabled = !this.enabled;
    if (this.enabled) {
      localStorage.setItem(audioEnabledStorageKey, 'on')
      this.plop();
    } else {
      localStorage.setItem(audioEnabledStorageKey, 'off');
    }
  }

  plop() {
    if (this.enabled) {
      this.plopAudio.play();
    }
  }
}
