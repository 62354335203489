<ion-header>
  <ion-toolbar>
    <ion-title>Dev Component</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <label>Image</label>
  <input type="file" (change)="onImageChange($event)"/>

  <div *ngFor="let img of catImages$ | async">
    {{img.id}}
    {{img.data | fileToImage}}
    <img [src]="img.data | fileToImage">
  </div>

  <ion-button color="danger" (click)="testError()">test error</ion-button>
</ion-content>
