import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Dc3Entry} from '../../../data/entry/entry';
import {Dc3Topic} from '../../../data/topic/topic';
import {TopicRepository} from '../../../data/topic/topic.repository';
import {AudioService} from '../../../services/audio.service';
import {EntryRepository} from '../../../data/entry/entry.repository';
import heic2any from 'heic2any';
import {readAndCompressImage} from 'browser-image-resizer';
import {imageDb} from '../../../data/images/image-db';
import {Dc3Planning} from '../../../data/planning/planning';
import {IonInput, IonTextarea, ToastController} from '@ionic/angular';
import {windowDebug} from '../../../tools/debug';
import {SwPush} from '@angular/service-worker';

@Component({
  selector: 'app-entry-editor',
  templateUrl: './entry-editor.component.html',
  styleUrls: ['./entry-editor.component.scss']
})
export class EntryEditorComponent implements OnInit {
  @ViewChild('fileInput') fileInput: any;
  @ViewChild(IonTextarea) textArea: IonTextarea | undefined;

  @Input() entry: Dc3Entry | undefined;
  @Input() planning: Dc3Planning | undefined;
  @Input() isFocused = false;

  @Input() rows = 4;
  @Input() showTimer = true;
  @Input() showBookmark = true;
  @Input() showBloglink = true;

  @Output() editorFocused = new EventEmitter<string>();

  entryContent = '';

  timerVisible = false;

  pinFormatter = (x: number) => x * 10;

  get isTracker() {
    return this.entry?.topic?.tracker?.range;
  }

  get unsavedChanges() {
    return this.entry?.content !== this.entryContent;
  }

  constructor(private topicRepository: TopicRepository,
              private entryRepository: EntryRepository,
              private audio: AudioService) {
  }


  ngOnInit() {
    if (this.entry) {
      this.entryContent = this.entry.content || '';
    } else {
      console.error('EntryEditor: entry should not be empty');
    }
  }

  onInputBlur() {
    windowDebug('EntryEditor', this);
    // console.log('editorBlur')
    this.updateContentIfChanged();
    this.editorFocused.emit(undefined);
    setTimeout(() => this.updateContentIfChanged(), 1000);
  }

  @HostListener('window:beforeunload', ['$event'])
  updateContentIfChanged() {
    if (this.entry?.content !== this.entryContent) {
      // console.log('editorBlur change Content')
      this.updateContent(this.entryContent);
    }
  }

  changeToFullEntry() {
    this.entryRepository.upsertEntries([{
      ...this.entry!,
      isFullEntry: true
    }]);
    this.audio.plop();
    setTimeout(() => this.textArea?.setFocus());
  }

  toggleTopicIsPinned(topic: Dc3Topic) {
    this.topicRepository.upsertTopics([{
      ...topic,
      isPinned: !topic.isPinned
    }]);
    this.audio.plop();
  }

  editValueByEvent(event: any, entry: Dc3Entry) {
    this.entryRepository.upsertEntries([{
      ...entry,
      value: event.detail.value
    }]);
    this.audio.plop()
  }

  editContentByEvent(event: any) {
    this.updateContent(event.detail.value);
  }

  updateContent(value: string) {
    // console.log('updateContent', value);
    if (this.entry) {
      this.entryRepository.upsertEntries([{
        ...this.entry!,
        content: value
      }]);
    }
  }

  imageProcessing = false;

  addImageToEntry(entry: Dc3Entry) {
    this.fileInput.nativeElement.click();
  }

  onPaste(ev: ClipboardEvent) {
    const items = (ev.clipboardData || (ev as any).originalEvent.clipboardData).items;
    let blob = null;
    for (const item of items) {
      if (item.type.indexOf('image') === 0) {
        ev.preventDefault();
        blob = item.getAsFile();
        this.processImage(blob);
        // console.log(blob); // Prints your files
      }
    }
  }

  async onImageChange(ev: any) {
    let file = ev.target.files[0];
    if (!file) {
      return;
    }
    await this.processImage(file);
  }

  async processImage(file: File) {
    this.imageProcessing = true;
    const fileName = file.name || `image.${file.type.split('/')[1]}}`;
    // console.log(file, file.size / 1e+6);

    if (file.type === 'image/heic') {
      file = await heic2any({
        blob: file,
        toType: 'image/jpeg'
      }) as File;
    }

    // console.log(file, file.size / 1e+6);

    file = await readAndCompressImage(file, {
      maxWidth: 1000,
      maxHeight: 1000
    }) as File;

    // console.log(file, file.size / 1e+6);

    const imageId = await imageDb.storeImage(fileName, file);

    this.entryContent = `${this.entryContent ? this.entryContent + '\n\n' : ''}![${fileName}](${imageId})`
    this.updateContent(this.entryContent);
    this.imageProcessing = false;
  }
}
