import {inject, Injectable} from '@angular/core';
import {getBlob, getMetadata, ref, Storage, uploadBytesResumable} from '@angular/fire/storage';
import {OnlineStatusService, OnlineStatusType} from 'ngx-online-status';
import {Auth, authState} from '@angular/fire/auth';
import {combineLatest, filter, from, map, startWith, take} from 'rxjs';
import {imageDb, ImageEntry} from './image-db';
import {liveQuery} from 'dexie';

@Injectable({
  providedIn: 'root'
})
export class FirebaseImageSyncService {
  private onlineStatusService = inject(OnlineStatusService);
  private auth = inject(Auth);
  private authState$ = authState(this.auth);

  private storage = inject(Storage);

  private isOnlineAndLoggedIn$ = combineLatest([
    this.onlineStatusService.status.pipe(startWith(this.onlineStatusService.getStatus())),
    this.authState$
  ]).pipe(map(([onlineStatus, authState]) => {
    return onlineStatus === OnlineStatusType.ONLINE && !!authState
  }))

  imagePath(imageId: string) {
    return `users/${this.auth.currentUser?.uid}/images/${imageId}`
  }

  storageRef(imageId: string) {
    return ref(
      this.storage,
      this.imagePath(imageId)
    )
  }

  constructor() {
    // (window as any)['imageSync'] = this;
    this.isOnlineAndLoggedIn$.pipe(
      filter(isOnlineAndLoggedIn => isOnlineAndLoggedIn),
      take(1)
    ).subscribe();

    const toSync$ = from(liveQuery(() =>
      imageDb.images.where('synced').notEqual(1).toArray()))

    combineLatest([
      this.isOnlineAndLoggedIn$,
      toSync$
    ]).pipe(map(([isOnlineAndLoggedIn, imageEntries]) => {
      if (isOnlineAndLoggedIn) {
        this.syncUp(imageEntries);
      }
    })).subscribe()
  }

  downloadImages() {

  }

  private syncUp(imageEntries: ImageEntry[]) {
    imageEntries.forEach(imageEntry => {
      uploadBytesResumable(
        this.storageRef(imageEntry.id),
        imageEntry.data,
        {
          customMetadata: {
            name: imageEntry.name,
            createdAt: imageEntry.createdAt.toString()
          }
        }
      ).then(res => {
        // console.log('imageUploaded', res);
        imageDb.images.update(imageEntry.id, {synced: 1})
      });
    })
  }

  /**
   * Images should only get downloaded, when needed.
   * Maybe its makes sense sometime to store thumbnails of images...
   * @param imageId
   */
  async downloadImage(imageId: string) {
    const ref = this.storageRef(imageId);
    const [blob, metadata] = await Promise.all([
      getBlob(ref),
      getMetadata(ref)
    ]);
    // console.log(blob, metadata);
    const imageEntry: ImageEntry = {
      id: imageId,
      name: metadata.customMetadata!['name'],
      synced: 1,
      data: blob,
      createdAt: new Date(metadata.customMetadata!['createdAt'])
    };
    imageDb.images.put(imageEntry);
    return imageEntry;
  }

}
