import {LOCALE_ID, NgModule, isDevMode} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {registerLocaleData} from '@angular/common';
import {MarkdownModule} from 'ngx-markdown';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CalendarCommonModule, CalendarWeekModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import {OnlineStatusModule} from 'ngx-online-status';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {DayComponent} from './day/day.component';
import {SettingsComponent} from './settings/settings.component';
import {ManifestComponent} from './manifest/manifest.component';
import {DevComponent} from './dev/dev.component';
import {FileToImagePipe} from './components/file-to-image.pipe';
import {ImageViewerComponent} from './image-viewer/image-viewer.component';
import {LoginComponent} from './login/login.component';
import {AccountComponent} from './account/account.component';
import {AppFirebaseModule} from './app-firebase.module';
import {environment} from '../environments/environment';
import {sentryProviders} from '../sentry';
import {ComponentsModule} from './components/components.module';
import {DayIndexBlockComponent} from './day/day-index-block/day-index-block.component';
import {IntroComponent} from './intro/intro.component';
import {RegisterComponent} from './register/register.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import {LandingComponent} from './landing/landing.component';

registerLocaleData(localeDe, localeDeExtra);

@NgModule({
  declarations: [
    LandingComponent,
    RegisterComponent,
    LoginComponent,
    AccountComponent,
    IntroComponent,

    AppComponent,

    DayComponent,
    DayIndexBlockComponent,

    ManifestComponent,
    SettingsComponent,

    ImageViewerComponent,

    DevComponent,
    FileToImagePipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    IonicModule.forRoot({mode: 'md'}),
    FormsModule,
    ReactiveFormsModule,
    MarkdownModule.forRoot(),
    BrowserAnimationsModule,
    CalendarCommonModule.forRoot(
      {provide: DateAdapter, useFactory: adapterFactory}
    ),
    CalendarWeekModule,
    //NgOptimizedImage,
    OnlineStatusModule,
    AppFirebaseModule,

    ComponentsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    ...(environment.production ? sentryProviders : []),
    {provide: LOCALE_ID, useValue: 'de'},
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
