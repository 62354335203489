import {Component, Input, OnInit} from '@angular/core';
import {AudioService} from '../../../services/audio.service';
import {Auth, user} from '@angular/fire/auth';
import {ModalController, PopoverController} from '@ionic/angular';
import {AccountComponent} from '../../account/account.component';

@Component({
  selector: 'app-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.scss']
})
export class HeaderNavComponent implements OnInit {
  @Input() defaultHref: string | undefined;

  user$ = user(this.auth);

  constructor(private auth: Auth,
              private modalController: ModalController,
              private popoverController: PopoverController,
              public audio: AudioService) {
  }

  ngOnInit() {
  }

  showAccountModal() {
    this.modalController.create({component: AccountComponent})
      .then(modal => modal.present());
  }

  showAccountPopover(ev: Event) {
    this.popoverController.create({
      component: AccountComponent,
      event: ev
    })
      .then(modal => modal.present());
  }
}
