import dayjs from 'dayjs';
import 'dayjs/locale/de'
import isBetween from 'dayjs/plugin/isBetween';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';


export const initDayJs = () => {
  dayjs.locale('de');
  dayjs.extend(isBetween);
  dayjs.extend(isToday);
  dayjs.extend(isYesterday);
}
