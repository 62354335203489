<ion-row class="ion-align-items-center">
  <ion-col size="auto">
    <input [(ngModel)]="timerTimeMin"
           [disabled]="timerActive"
           (change)="resetTimer()"
           style="width: 50px;">
    min
  </ion-col>
  <ion-col size="auto">
    <ion-button fill="clear"
                (click)="toggleTimer()">
      <ion-icon [name]="timerActive ? 'pause-outline' : 'play-outline'"></ion-icon>
    </ion-button>
    <ion-button fill="clear"
                (click)="resetTimer()">
      <ion-icon name="refresh-outline"></ion-icon>
    </ion-button>
  </ion-col>
  <ng-container *ngIf="{value: timerSeconds$ | async} as timerSeconds">
    <ng-container *ngIf="timerSeconds.value !== null">
      <ion-col size="auto">
        <ng-container *ngIf="timerSeconds.value >= 0">
          Verbleibend: {{timerSeconds.value * 1000 | date:'mm:ss'}}
        </ng-container>
        <!--      <ng-container *ngIf="timerSeconds < 0">-->
        <!--        Abgelaufen: {{timerSeconds * 1000 | date: 'mm:ss'}}-->
        <!--      </ng-container>-->
      </ion-col>
      <ion-col *ngIf="timerSeconds.value < 0">
        <ion-text color="danger">
          Drüber: {{timerSeconds.value * -1 * 1000 | date:'mm:ss'}}
        </ion-text>
      </ion-col>
    </ng-container>
  </ng-container>

</ion-row>

