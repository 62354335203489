import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileToImage'
})
export class FileToImagePipe implements PipeTransform {

  transform(value: Blob): unknown {
    // console.log(value)
    return URL.createObjectURL(value);
  }

}
