import {Component, OnInit, ViewChild} from '@angular/core';
import {IonContent, ModalController} from '@ionic/angular';
import {createTopic, Dc3Topic} from '../../data/topic/topic';
import {TopicRepository} from '../../data/topic/topic.repository';

interface StartTracker {
  name: string;
  rangeTarget: 'HIGH' | 'LOW';
}

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent implements OnInit {
  @ViewChild(IonContent) content?: IonContent;
  step: 'start' | 'topicInfo' | 'tracker' | 'topics' | 'info' = 'start';

  startTracker: StartTracker[] = [
    {name: 'Schlaf', rangeTarget: 'HIGH'},
    {name: 'Krise', rangeTarget: 'LOW'},
    {name: 'Freude', rangeTarget: 'HIGH'},
    {name: 'Dankbarkeit', rangeTarget: 'HIGH'},
    {name: 'Soziales', rangeTarget: 'HIGH'},
    {name: 'Bewegung', rangeTarget: 'HIGH'},
    {name: 'Ernährung', rangeTarget: 'HIGH'},
    {name: 'Achtsamkeit', rangeTarget: 'HIGH'},
    {name: 'Energie', rangeTarget: 'HIGH'},
    {name: 'Ärger', rangeTarget: 'LOW'},
    {name: 'Stimmung', rangeTarget: 'HIGH'},
    {name: 'Nervosität', rangeTarget: 'LOW'},
    {name: 'Liebe', rangeTarget: 'HIGH'},
    {name: 'Skills anwenden', rangeTarget: 'HIGH'}
  ];

  startTopics: string[] = [
    'Tagebuch',
    'Hobbies',
    'Ordnung',
    'Fotografieren',
    'Malen',
    'Schöne Erlebnisse',
    'Träume',
    'Schreiben',
    'Kochen',
    'Backen',
    'Finanzen',
    'Philosophie',
    'Erfolge',
    'Wusste ich nicht',
    'Sauerteig',
    'Meditation',
    'Lesen',
    'Poesie',
    'Gedichte',
    'Mein Buch',
    'Kaffeekochen',
    'Lieblingsrestaurants',
    'Rezepte',
    'Neue Alben',
    'Hanteltraining',
    'Therapie',
    'Beziehungen',
    'Nähen',
    'Basteln',
    'Pen-&-Paper',
    'Urlaubsideen'
  ]


  selectedTracker: StartTracker[] = [];

  selectedTopics: string[] = [];

  constructor(public modalController: ModalController,
              private topicRepository: TopicRepository) {
  }

  ngOnInit() {
  }

  selectTracker(ev: any) {
    console.log('selected', ev);
    if (ev.detail.checked) {
      this.selectedTracker = [
        ...this.selectedTracker,
        ev.detail.value
      ]
    } else {
      this.selectedTracker = this.selectedTracker.filter(x => x.name !== ev.detail.value.name)
    }
  }

  trackerIsSelected(tracker: StartTracker) {
    return this.selectedTracker.find(x => x.name === tracker.name);
  }


  selectTopic(topicName: string) {
    if (this.selectedTopics.includes(topicName)) {
      this.selectedTopics = this.selectedTopics.filter(x => x !== topicName);
    } else {
      this.selectedTopics = [...this.selectedTopics, topicName];
    }
  }

  showInfo() {
    this.step = 'info';
    setTimeout(() => {
      this.content?.scrollToTop();
    })
  }

  start() {
    const topics: Dc3Topic[] = []
    let lastIndex: number = 0;

    this.selectedTracker.forEach((tracker, index) => {
      const newTopic = createTopic(tracker.name, index);
      newTopic.tracker = {
        range: true,
        rangeTarget: tracker.rangeTarget
      };
      lastIndex = index;
      topics.push(newTopic);
    });

    this.selectedTopics.forEach(name => {
      lastIndex++;
      const newTopic = createTopic(name, lastIndex);
      topics.push(newTopic);
    });

    this.topicRepository.upsertTopics(topics);
    console.log(topics);
    this.modalController.dismiss();
  }
}
