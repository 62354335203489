import {Injectable} from '@angular/core';
import {NotificationService} from '../../../services/notification.service';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class TimerService {
  webWorker?: Worker;

  timers: { [id: string]: BehaviorSubject<number> } = {};
  activeTimerId: string | null = null;

  constructor(private notificationService: NotificationService) {
    this.webWorker = new Worker('assets/scripts/background-timer.js');
    this.webWorker.onmessage = (event) => {
      if (event.data === 'tick') {
        this.tick();
      }
    }
  }

  registerTimer(timerId: string, startSeconds: number) {
    this.timers[timerId] = new BehaviorSubject<number>(startSeconds);
    return this.timers[timerId].asObservable();
  }

  toggleTimer(timerId: string) {
    if (this.activeTimerId === timerId) {
      this.stopTimer();
    } else {
      this.startTimer(timerId);
    }
  }

  resetTimer(timerId: string, startSeconds: number)  {
    this.stopTimer();
    this.timers[timerId].next(startSeconds);
  }

  private startTimer(timerId: string) {
    this.activeTimerId = timerId
    this.webWorker?.postMessage('startTimer');
  }

  private stopTimer() {
    this.activeTimerId = null;
    this.webWorker?.postMessage('stopTimer');
  }

  private tick() {
    if (!this.activeTimerId) {
      throw Error('This should not happen, should have active timer id when running')
    }
    const subj = this.timers[this.activeTimerId];
    subj.next(subj.value - 1);
  }
}
