import {Component, OnInit, ViewChild} from '@angular/core';
import {TopicRepository} from '../../data/topic/topic.repository';
import {map} from 'rxjs';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  @ViewChild('backupDownloadAnchor') backupDownloadAnchor: any;
  backupDownloadString: SafeUrl |undefined;
  backupFileName = '';

  topics$ = this.topicRepository.topics$.pipe(
    map(topics => topics.sort((x, y) => {
      return x.tracker?.range === y.tracker?.range ? 0 : x.tracker?.range ? -1 : 1;
    }))
  );

  constructor(private topicRepository: TopicRepository,
              private sanitizer: DomSanitizer) {
    (window as any)['dc3Settings'] = this;
  }

  ngOnInit() {
  }

  downloadBackup() {
    if(this.backupDownloadAnchor) {
      const data = {
        entries: localStorage.getItem('entries@store'),
        topics: localStorage.getItem('topics@store')
      }
      console.log(data);
      const jsonString = JSON.stringify(data);
      const uri = this.sanitizer.bypassSecurityTrustUrl("data:text/json;charset=UTF-8," + encodeURIComponent(jsonString));
      this.backupDownloadString = uri;
      this.backupFileName = `dc3-${dayjs().unix()}.json`;
      setTimeout(() => {
        this.backupDownloadAnchor.nativeElement.click()
      })
    }
  }

}
