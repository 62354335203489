<ion-header>
  <div class="container">
    <ion-toolbar>
      <ion-title>
        Einstellungen
      </ion-title>
    </ion-toolbar>
  </div>
</ion-header>

<ion-content class="ion-padding">

  <a #backupDownloadAnchor
     style="display: none"
     title="Download JSON"
     [href]="backupDownloadString"
     [download]="backupFileName">
  </a>

  <ion-button (click)="downloadBackup()">backup</ion-button>

</ion-content>
