<input #fileInput
       type="file"
       style="display: none;"
       (change)="onImageChange($event)"/>

<div *ngIf="entry"
     class="simple-card"
     [style.--border-color]="entry.topic.color"
     [class.low-opacity]="!isTracker && !this.entryContent && !isFocused">

  <div class="entry-header">
    <app-styled-topic [topic]="entry.topic"
                      class="entry-header__topic topic-matched-borders topic-button mr-10"
                      style="font-size: 20px;"
                      [routerLink]="['/topics', entry.topic.id]">
    </app-styled-topic>
    <div class="entry-header__buttons">
      <ion-button *ngIf="showTimer"
                  fill="clear"
                  (click)="timerVisible = !timerVisible">
        <ion-icon slot="icon-only" name="timer-outline"></ion-icon>
      </ion-button>
      <ion-button *ngIf="!entry.isFullEntry"
                  fill="clear"
                  (click)="changeToFullEntry()">
        <ion-icon slot="icon-only" name="create-outline">
        </ion-icon>
      </ion-button>
      <ion-button *ngIf="!isTracker && showBookmark"
                  fill="clear"
                  (click)="toggleTopicIsPinned(entry.topic)">
        <ion-icon slot="icon-only"
                  [name]="'bookmark' + (entry.topic.isPinned ? '' : '-outline')">
        </ion-icon>
      </ion-button>
      <ion-button *ngIf="showBloglink"
                  fill="clear"
                  [routerLink]="['/topics', entry.topic.id]">
        <ion-icon slot="icon-only" name="open-outline">
        </ion-icon>
      </ion-button>
    </div>
  </div>


  <div class="ion-padding">

    <app-timer *ngIf="timerVisible"
               [name]="entry.topic.name">
    </app-timer>


    <ion-row *ngIf="isTracker">
      <ion-col>
        <ion-range class="ion-no-padding"
                   [value]="entry.value"
                   step="0.05"
                   [snaps]="true"
                   min="0"
                   max="1"
                   [pin]="true"
                   [pinFormatter]="pinFormatter"
                   [color]="entry | rangeTrackerColor"
                   (ionChange)="editValueByEvent($event, entry)">
        </ion-range>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="planning">
      <ion-col size="auto">
        {{planning.startDate | date: 'shortTime'}}
        -
        {{planning.endDate | date: 'shortTime'}}
      </ion-col>
      <ion-col size="auto">
        {{planning.description}}
      </ion-col>
    </ion-row>

    <ng-container *ngIf="entry.isFullEntry">
      <ion-textarea [(ngModel)]="entryContent"
                    class="mt-10"
                    fill="outline"
                    [rows]="rows"
                    [autoGrow]="true"
                    [debounce]="500"
                    (paste)="onPaste($event)"
                    (ionFocus)="editorFocused.emit(entry.topic.id)"
                    (ionBlur)="onInputBlur()">
      </ion-textarea>

      <ion-row class="ion-justify-content-between ion-align-items-center ion-no-padding mt-10">
        <ion-col size="auto" class="ion-no-padding">
          <ion-button *ngIf="!imageProcessing"
                      color="medium"
                      fill="outline"
                      size="small"
                      (click)="addImageToEntry(entry)">
            <ion-icon slot="icon-only" name="image-outline"></ion-icon>
          </ion-button>
          <ion-spinner *ngIf="imageProcessing"
                       name="dots"
                       style="width: 25px; height: 25px;">
          </ion-spinner>
        </ion-col>
        <ion-col size="auto" class="ion-no-padding">

          <ion-button *ngIf="unsavedChanges"
                      color="medium"
                      fill="outline"
                      size="small">
            speichern
          </ion-button>


          <!--        <ion-button-->
          <!--            color="danger"-->
          <!--            fill="outline"-->
          <!--            size="small"-->
          <!--            (click)="removeEntry()">-->

          <!--        </ion-button>-->
        </ion-col>
      </ion-row>
    </ng-container>

  </div>
</div>
