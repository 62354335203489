<ion-content>
  <div class="hero-section">
    <div class="container">
      <div>
        <h1 style="color: black;">Diary Card 3</h1>
        <div class="subheading">
          Dein Leben, Tag für Tag:
          <br>
          Die ultimative Journaling und Moodtracking-App
        </div>
      </div>
    </div>
    <!--    <img src="assets/marketing/device-mockup-marble.jpg" />-->


    <ion-button class="login-button"
                fill="clear"
                color="medium"
                routerLink="login">
      <ion-icon name="log-in-outline"></ion-icon>
      anmelden
    </ion-button>

  </div>

  <div class="container py-50">

    <ion-row class="ion-align-items-center">
      <ion-col size="12" size-sm="4" push-sm="8">
        <img class="content-image"
             src="assets/landing/small/no-red-background.jpg"
             alt="Word No on red background"/>
      </ion-col>
      <ion-col size="12" size-sm="7" pull-sm="4">
        <h2>Digitales Journaling ohne Bullshit</h2>
        <p>
          dc3 bietet dir die Möglichkeit, deine Emotionen zu erkunden, deine Ziele zu verfolgen und dein inneres
          Wachstum aufzuzeichnen.
        </p>
        <p>
          Ganz gleich, ob du nach mehr Freude im Alltag suchst oder deinen kreativen Prozess dokumentieren möchtest, wir
          sind hier, um dich zu unterstützen.
        </p>
      </ion-col>
    </ion-row>

    <ion-row class="mt-20 ion-align-items-center">
      <ion-col size="12" size-sm="4">
        <img class="content-image"
             src="assets/landing/small/person-in-subway.jpg"
             alt="person in subway"/>
      </ion-col>
      <ion-col size="12" size-sm="7" push-sm="1">
        <h2>Dein Tagebuch. Jeden Tag anders.</h2>
        <p>
          Die meisten Tagebücher bieten entweder volle Freiheit oder fixierte Fragen. Sich jeden Tag zu fragen, wofür
          man dankbar ist, nervt irgendwann.
        </p>
        <p>
          Vielleicht möchtest du heute etwas über Hass oder doch über den Fortschritt deines Bastelprojekts schreiben?
          Ganz egal. Am Ende kannst du sehen, was du über die verschiedensten Dinge gedacht hast und was du im Laufe
          der Zeit erreicht hast.
        </p>
      </ion-col>
    </ion-row>
  </div>

  <img class="image-journaling"
       src="assets/landing/large/person-journaling.jpg"
       alt="person journaling on bed"/>


  <div class="container py-50">

    <ion-row>
      <ion-col>
        <h2 style="font-size: 70px;">Konzept</h2>
      </ion-col>
    </ion-row>

    <ion-row class="mt-40 ion-align-items-center">
      <ion-col size="12" size-sm="4">
        <img class="content-image area-shot-image"
             src="assets/landing/area-shots/small/my_day-light.jpg"/>
      </ion-col>
      <ion-col size="12" size-sm="7" push-sm="1">
        <h3>Mein Tag</h3>
        <p>
          Hier spielt sich die Magie ab: Fülle täglich deine Tracker aus und schreibe auf, was dir sonst noch so
          einfällt. Sobald du ein Thema einmal angelegt hast, wird es dir immer wieder vorgeschlagen.
        </p>
        <p>
          Besonders wichtige Themen, kannst du mit einem Lesezeichen versehen, damit sie dir jeden Tag angelegt
          angezeigt werden.
        </p>
      </ion-col>
    </ion-row>

    <ion-row class="mt-40 ion-align-items-center">
      <ion-col size="12" size-sm="4">
        <img class="content-image area-shot-image"
             src="assets/landing/area-shots/small/plan-light.jpg"/>
      </ion-col>
      <ion-col size="12" size-sm="7" push-sm="1">
        <h3>Planen</h3>
        <p>
          Mit dem Kalender kannst du deine Woche planen, indem du Themen per Drag&Drop darauf ablegst. Du kannst auch
          durch einen Klick in den Kalender neue Einträge erstellen, oder bestehende Einträge durch einen Klick darauf
          bearbeiten oder Löschen.
        </p>
        <p>
          Deine Kalendereinträge werden dir am entsprechenden Tag ganz oben in 'mein tag', zusammen mit der Beschreibung
          die du vielleicht eingegeben hast angezeigt.
        </p>
      </ion-col>
    </ion-row>

    <ion-row class="mt-40 ion-align-items-center">
      <ion-col size="12" size-sm="4">
        <img class="content-image area-shot-image"
             src="assets/landing/area-shots/small/blog-light.jpg"/>
      </ion-col>
      <ion-col size="12" size-sm="7" push-sm="1">
        <h3>Persönlicher Blog</h3>
        <p>
          Hier geht es um deine Inhalte. Du möchtest deine kreativen Erfolge nachvollziehen? Dich daran erinnern was du
          letzte Woche gemacht hast? Deine Fortschritte mit jemandem teilen, ohne ins Stottern zu geraten?
        </p>
        <p>
          Dafür gibt es den Blog! Lass dir alle Einträge von einem Thema oder von mehreren Themen gleichzeitig
          anzeigen.
        </p>
      </ion-col>
    </ion-row>

    <ion-row class="mt-40 ion-align-items-center">
      <ion-col size="12" size-sm="4">
        <img class="content-image area-shot-image"
             src="assets/landing/area-shots/small/understand-light.jpg"/>
      </ion-col>
      <ion-col size="12" size-sm="7" push-sm="1">
        <h3>Verstehen</h3>
        <p>
          Wenn du die App schon ein paar Tage benutzt hast, möchtest du vielleicht wissen wie sich deine Werte
          entwickelt haben. Unter 'verstehen' bekommst du Wochendurchschnitte und die Veränderung zu der Woche davor.
        </p>
        <p>
          Darüber hinaus findest du ein interaktives Diagram und die einzelnen Wochentage, was dir dabei helfen kann, zu
          verstehen, warum du dich so fühlst wie du dich fühlst.
        </p>
      </ion-col>
    </ion-row>

  </div>

  <div *ngIf="alphaKey"
       class="newsletter-box">
    <div class="container py-20">
      <h2>Du hast einen AlphaKey!</h2>
      <p>
        Hiermit kannst du die Vorabversion der Software ausprobieren! Du benötigst einen Account, da die Testversion nur
        online funktioniert.
      </p>
      <p>
        Bitte mach dir bewusst, dass ich dieses Tool alleine programmiert habe und keinerlei Gewähr bieten kann.
        Darüber hinaus möchte ich dir mitteilen, dass die App zurzeit für den Desktop optimiert ist und momentan
        wenig Sinn auf Mobilgeräten macht, außer vielleicht zum anzeigen des Blogs.
      </p>
      <p>
        Falls das für dich kein Problem darstellt, wünsche ich dir viel Spaß beim ausprobieren!
      </p>
      <ion-button [routerLink]="['register', this.alphaKey]">
        Registrieren
      </ion-button>
    </div>
  </div>

  <!--  <div class="newsletter-box">-->
  <!--    <div class="container py-50">-->
  <!--      <ion-row class="ion-align-items-center">-->

  <!--        <ion-col size="12" size-sm="6">-->
  <!--          <h2 class="m-0">Auf dem Laufenden bleiben</h2>-->
  <!--          <div>Abonniere jetzt den dc3 Newsletter!</div>-->
  <!--        </ion-col>-->

  <!--        <ion-col size="12" size-sm="6">-->
  <!--          <ion-row class="ion-align-items-center">-->
  <!--            <ion-col>-->
  <!--              <ion-input label="Deine E-Mail Adresse"-->
  <!--                         label-placement="floating"-->
  <!--                         fill="outline">-->
  <!--              </ion-input>-->
  <!--            </ion-col>-->
  <!--            <ion-col size="auto">-->
  <!--              <ion-button>-->
  <!--                anmelden-->
  <!--              </ion-button>-->
  <!--            </ion-col>-->
  <!--          </ion-row>-->
  <!--        </ion-col>-->

  <!--      </ion-row>-->
  <!--    </div>-->
  <!--  </div>-->

</ion-content>
