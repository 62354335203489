import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {combineLatest, map, Observable, of, switchMap, take} from 'rxjs';
import {ModalController, ViewWillLeave} from '@ionic/angular';
import {ActivatedRoute} from '@angular/router';
import dayjs from 'dayjs';

import {createEntry, Dc3Entry} from '../../data/entry/entry';
import {Dc3Topic} from '../../data/topic/topic';
import {EntryRepository} from '../../data/entry/entry.repository';
import {TopicRepository} from '../../data/topic/topic.repository';
import {AudioService} from '../../services/audio.service';
import {PlanningRepository} from '../../data/planning/planning.repository';
import {Dc3Planning} from '../../data/planning/planning';
import {EntryEditorComponent} from '../components/entry-editor/entry-editor.component';
import {dateFormat, TimeFrame} from '../../tools/date';
import {IndexBlockViewModel} from './day-index-block/day-index-block.component';
import {windowDebug} from '../../tools/debug';
import {Title} from '@angular/platform-browser';


interface PlanningEntryViewModel {
  planning: Dc3Planning;
  entry: Dc3Entry;
}
@Component({
  selector: 'app-day',
  templateUrl: './day.component.html',
  styleUrls: ['./day.component.scss']
})
export class DayComponent implements OnInit, ViewWillLeave {
  @ViewChildren(EntryEditorComponent) entryEditors: QueryList<EntryEditorComponent> | undefined;

  trackPlanningEntries = (index: number, x: PlanningEntryViewModel) => x.planning.id;
  trackEntries = (index: number, x: Dc3Entry) => x.id;

  focusedTopicId: string | undefined;

  date$: Observable<string> = this.route.url.pipe(
    switchMap(url => {
      if (url.toString() === 'today') {
        let currentDate = dayjs();
        // 3am is basically still yesterday :D
        if (currentDate.hour() < 3) {
          currentDate = currentDate.subtract(1, 'day');
        }
        return of(currentDate.format(dateFormat))
      } else {
        return this.route.params.pipe(
          map(x => x['date'])
        )
      }
    })
  );

  dateIsYesterday$ = this.date$.pipe(
    map(x => dayjs(x).isYesterday())
  );

  entries$ = this.date$.pipe(switchMap(
    date => this.entryRepository.getEntriesByDate$(date)
  ))
  // .pipe(tap(x => console.log('Entries: ', x)))


  // create temporary empty entries for bookmarked topics so
  // they wont get recreated on every change (trackBy would not work)
  emptyEntries: Dc3Entry[] = [];

  getEmptyEntry(topic: Dc3Topic, date: string): Dc3Entry {
    let emptyEntry = this.emptyEntries.find(x => x.topicId === topic.id);
    if (!emptyEntry) {
      emptyEntry = createEntry(topic, false, date);
      this.emptyEntries.push(emptyEntry);
    }
    return emptyEntry;
  }

  planningEntries$: Observable<PlanningEntryViewModel[]> =
    combineLatest([
      this.date$.pipe(switchMap((date: string) =>
        this.planningRepository.planningsForDay$(date))),
      this.entries$,
      this.date$
    ]).pipe(
      map(([plannings, entries, date]) => {
        return plannings.map(planning => {
          const topic = this.topicRepository.getTopic(planning.topicId);
          if (!topic) {
            throw Error('This should not happen');
          }
          let entry = entries.find(x => x.topicId === topic.id);
          if (!entry) {
            entry = this.getEmptyEntry(topic, date);
          }
          return {planning, entry}
        })
      })
    );

  trackerEntries$: Observable<Dc3Entry[]> = combineLatest([
    this.topicRepository.trackerTopics$,
    this.planningEntries$,
    this.date$
  ]).pipe(
    switchMap(([topics, planningEntries, date]) => this.entries$.pipe(
      map(entries => {
        return topics
          .filter(x => !planningEntries.some(y => x.id === y.entry.topicId))
          .filter(x => x.tracker?.range)
          .map(x => {


            const existingEntry = entries.find(e => e.topicId === x.id);
            if (existingEntry) {
              return existingEntry;
            }
            return createEntry(x, false, date)
          })
      })
    ))
  );

  fullEntries$: Observable<Dc3Entry[]> = combineLatest([
    this.entries$,
    this.topicRepository.pinnedTopics$,
    this.planningEntries$,
    this.date$
  ]).pipe(
    map(([entries, pinnedTopics, planningEntries, date]) => {
      pinnedTopics = pinnedTopics.filter(
        topic => !entries.some(entry => entry.topicId === topic.id)
      );
      entries = [
        ...entries,
        ...pinnedTopics.map(topic => this.getEmptyEntry(topic, date))
      ];

      entries = entries
        .filter(x => !x.topic?.tracker?.range)
        .filter(
          entry => !planningEntries.some(
            planningEntry => entry.topicId === planningEntry.entry.topicId)
        )

      return entries;
    })
  );

  pinnedEntries$ = this.fullEntries$.pipe(
    map(entries => entries.filter(x => x.topic.isPinned))
  );

  otherEntries$ = this.fullEntries$.pipe(
    map(entries => entries.filter(x => !x.topic.isPinned))
  );

  suggestedTopics$ = this.topicRepository
    .standardTopics$.pipe(
      switchMap(topics => this.fullEntries$.pipe(
        map(existingEntries => topics.filter(
          topic => !existingEntries.find(entry => entry.topicId === topic.id)
        ))
      ))
    );

  index$: Observable<IndexBlockViewModel[]> = combineLatest([
    this.planningEntries$,
    this.trackerEntries$,
    this.pinnedEntries$,
    this.otherEntries$
  ]).pipe(map(([
                 planningEntries,
                 trackerEntries,
                 pinnedEntries,
                 otherEntries
               ]) => {
    return [
      {
        title: 'Geplant',
        icon: 'calendar',
        entries: planningEntries.map(x => x.entry)
      },
      {
        title: 'Tracker',
        icon: 'bar-chart',
        entries: trackerEntries
      },
      {
        title: 'Lesezeichen',
        icon: 'bookmark',
        entries: pinnedEntries
      },
      {
        title: 'Sonstige',
        icon: 'bulb',
        entries: otherEntries
      }
    ]
  }))


  constructor(private route: ActivatedRoute,
              private topicRepository: TopicRepository,
              private planningRepository: PlanningRepository,
              private entryRepository: EntryRepository,
              private modalController: ModalController,
              private audio: AudioService,
              private title: Title) {

    windowDebug('DayComponent', this);
  }


  ngOnInit() {
    this.date$.pipe().subscribe(date => {
      // console.log('DAYCOMPONENT DATE', date);
      this.planningRepository.setFetchTimeFrame(new TimeFrame(dayjs(date).toDate(), 'day'))

      this.entryRepository.setQueryConstraints(new TimeFrame(dayjs(date).toDate(), 'day'))
    })
  }

  ionViewDidEnter() {
    this.title.setTitle('dc3 | mein tag');
  }

  ionViewWillEnter() {
    // this.showManifest();
  }

  ionViewWillLeave() {
    this.entryRepository.clearEmptyEntries();
  }

  // showManifest() {
  //   this.modalController.create({
  //     component: ManifestComponent,
  //     backdropDismiss: true,
  //     keyboardClose: true
  //   }).then(modal => modal.present());
  // }

  addEntry(topic: Dc3Topic) {
    this.date$.pipe(take(1)).subscribe(date => {
      this.entryRepository.upsertEntries([
        createEntry(topic, true, date
        )]);
      setTimeout(() => {
        this.scrollToAndFocusTopic(topic.id);
        this.audio.plop();
      });
    });

  }

  async addEntryWithNewTopic(topicName: any) {
    if (topicName) {
      const newTopic = await this.topicRepository.createTopic(topicName);
      this.topicRepository.upsertTopics([newTopic]);
      this.addEntry(newTopic);
    }
  }

  onEditorFocused(topicId: string) {
    this.focusedTopicId = topicId;
  }

  updateTopics(topics: Dc3Topic[]) {
    this.topicRepository.upsertTopics(topics);
  }

  scrollToAndFocusTopic(topicId: string) {
    if (this.entryEditors) {
      const editor = this.entryEditors.find(x => x.entry?.topicId === topicId);
      if (!editor || !editor.entry) {
        throw Error('This should not happen!')
      }

      if (!editor.entry.isFullEntry) {
        editor.changeToFullEntry()
      }
      setTimeout(() => {
        editor.textArea?.setFocus();
      });
    }
  }
}
