import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {IonicModule} from '@ionic/angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {MarkdownModule} from 'ngx-markdown';


const sharedDeclarations: any[] = [
]

const sharedModules = [
  CommonModule,
  IonicModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  MarkdownModule.forChild()
]


@NgModule({
  declarations: [
    ...sharedDeclarations
  ],
  imports: [
    ...sharedModules
  ],
  exports: [...sharedDeclarations, ...sharedModules]
})
export class SharedModule {
}
