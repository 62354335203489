export const defaultTopicColors = [
  '#7b3a3a', '#cb3636',
  '#7b3a76', '#b036cb',
  '#3a457b', '#3660cb',
  '#3a787b', '#3fa69a',
  '#3a7b42', '#30b032',
  '#7a7b3a', '#cb8f36',
  '#7b683a', '#cb6336'
];

export const getRandomTopicColor = () => {
  const random = Math.floor(Math.random() * defaultTopicColors.length);
  return defaultTopicColors[random];
}
