import {Store} from '@ngneat/elf';
import {persistState} from '@ngneat/elf-persist-state';
import * as localForage from 'localforage';
import {of} from 'rxjs';
import {environment} from '../environments/environment';

localForage.config({
  driver: localForage.INDEXEDDB,
  name: 'app_data',
  version: 1.0,
  storeName: 'elf_persistence',
});

export const appPersistState = <S extends Store>(store: S) => {
  if(environment.elfPersistence) {
    return persistState(store, {
      storage: localForage
    })
  }
  return {initialized$: of(true)};
}
