<div *ngIf="topics?.length" class="simple-card mb-20">
  <div class="mb-10">
    <ion-badge color="light">
      <ion-icon class="mr-5" [name]="icon"></ion-icon>
      {{header}}
    </ion-badge>
  </div>

  <app-styled-topic *ngFor="let topic of topics"
                    [topic]="topic"
                    class="topic-button mb-10 mr-10"
                    style="font-size: 13px"
                    [class.topic-outline]="!selectedTopicIds?.includes(topic.id)"
                    (click)="topicClick.emit(topic)">
  </app-styled-topic>

</div>
