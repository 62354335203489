import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CalendarView} from 'angular-calendar';
import {dateFormat, TimeFrame} from '../../../tools/date';
import dayjs from 'dayjs';

@Component({
  selector: 'app-calendar-header',
  templateUrl: './calendar-header.component.html',
  styleUrls: ['./calendar-header.component.scss']
})
export class CalendarHeaderComponent {
  CalendarView = CalendarView;
  @Input() view: CalendarView = CalendarView.Week;
  @Input() timeFrame: TimeFrame | undefined;
  @Input() locale: string = 'de-DE';
  @Output() viewChange = new EventEmitter<CalendarView>();
  @Output() timeFrameChange = new EventEmitter<TimeFrame>();

  get todayIsInTimeFrame() {
    return this.timeFrame?.days.includes(dayjs().format(dateFormat));
  }
}

