import {createStore} from '@ngneat/elf';
import {selectManyByPredicate, updateEntitiesByPredicate, upsertEntities, withEntities} from '@ngneat/elf-entities';
import {Injectable} from '@angular/core';

import {Dc3DiagramDataEntry, Dc3DiagramViewModel, Dc3Entry} from './entry';
import * as dayjs from 'dayjs';
import {dateFormat, sortByDate, TimeFrame} from '../../tools/date';
import {map, Observable, ReplaySubject, Subscription, switchMap} from 'rxjs';
import {TopicRepository, topicsObj$} from '../topic/topic.repository';
import {Dc3Topic} from '../topic/topic';
import {FirebaseSyncService} from '../firebase-sync.service';
import {QueryConstraint} from '@firebase/firestore';
import {getDocs, limit, orderBy, query, startAfter, where} from '@angular/fire/firestore';
import {appPersistState} from '../persistence';
// import {buildMock} from '../../tools/mock-builder';
import {windowDebug} from '../../tools/debug';


const entryStore = createStore(
  {name: 'entries'},
  withEntities<Dc3Entry>()
);

const persistence = appPersistState(entryStore);

const addTopics = (source: Observable<Dc3Entry[]>): Observable<Dc3Entry[]> => {
  return source.pipe(switchMap(entries => topicsObj$.pipe(
    map(topics => entries.map(e => ({...e, topic: topics[e.topicId]})))
  )));
}

const sortByTopicOrder = (source: Observable<Dc3Entry[]>): Observable<Dc3Entry[]> => {
  return source.pipe(map(entries => {
    entries.sort((a, b) => {
      const valA = a.topic?.order !== undefined ? a.topic.order : 9999;
      const valB = b.topic?.order !== undefined ? b.topic.order : 9999;
      return valA - valB
    })
    return entries;
  }))
}

@Injectable({providedIn: 'root'})
export class EntryRepository {
  private fetchQueryConstraints$ =
    new ReplaySubject<QueryConstraint[]>(1);


  entries$ = entryStore.pipe(
    selectManyByPredicate(x => !x.removed),
    addTopics
  );

  constructor(private topicRepository: TopicRepository,
              private firebaseSyncService: FirebaseSyncService) {
    windowDebug('entryRepository', this);
    persistence.initialized$.subscribe(initialized => {
      if (initialized) {

        //todo implement temp entries when adding new!
        this.clearEmptyEntries();

        this.firebaseSyncService.registerStore({
          store: entryStore,
          fetchQueryConstraints$: this.fetchQueryConstraints$.asObservable()
        });
      }
    });
  }

  setQueryConstraints(timeFrame: TimeFrame, topicId?: string) {
    const constraints = timeFrame.queryConstraints();
    if (topicId) {
      constraints.push(where('topicId', '==', topicId))
    }
    this.fetchQueryConstraints$.next(constraints);
  }


  async getBlogEntries(topicIds: string[], lastEntryId?: string): Promise<Dc3Entry[]> {
    const queryConstraints: QueryConstraint[] = []
    if (topicIds.length) {
      queryConstraints.push(where('topicId', 'in', topicIds))
    }

    queryConstraints.push(orderBy('date', 'desc'));

    if (lastEntryId) {
      const lastVisible = await this.firebaseSyncService.getDocumentSnapshotFor(entryStore.name, lastEntryId);
      queryConstraints.push(startAfter(lastVisible))
    }

    queryConstraints.push(limit(10));

    const q = query(
      this.firebaseSyncService.collectionRef(entryStore.name),
      ...queryConstraints
    );

    const docs = await getDocs(q).then(docs => docs.docs)
    let entries = docs.map(doc => doc.data()) as Dc3Entry[];
    const topics = this.topicRepository.getTopicsObj();
    entries = entries.map(entry => {
      return {
        ...entry,
        topic: topics[entry.topicId]
      }
    });
    sortByDate(entries);
    return entries;
  }

  clearEmptyEntries() {
    entryStore.update(
      // // probably creates some junk datasets but is required for sync at moment
      updateEntitiesByPredicate(
        x => x.value === null && (x.content === null || x.content === ''),
        {removed: true, synced: false}
      ),
      updateEntitiesByPredicate(
        x => {
          //clear empty tracker entries
          const isTracker = !!this.topicRepository.getTopic(x.topicId)?.tracker?.range;
          const hasTrackerValue = x.value !== undefined;
          const hasEmptyContent = x.content === null || x.content === '';

          return x.isFullEntry && isTracker && hasTrackerValue && hasEmptyContent;
        },
        {isFullEntry: false, synced: false}
      )
    );
  }

  entriesByTimeFrame$(timeFrame: TimeFrame) {
    return entryStore.pipe(
      selectManyByPredicate(x =>
          !x.removed
          && dayjs(x.date).isBetween(
            timeFrame.startDate, timeFrame.endDate,
            'day', '[]'
          )
      ),
      sortByTopicOrder,
      addTopics
      // tap(x => console.log('checktopics', x)),
    );
  }

  getDiagramDataByRange$(timeFrame: TimeFrame): Observable<Dc3DiagramViewModel> {
    return entryStore.pipe(selectManyByPredicate(entry => timeFrame.isBetween(entry.date)))
      .pipe(
        addTopics,
        sortByTopicOrder,
        map(entries => {
          let topics: Dc3Topic[] = []
          let diagramData: Dc3DiagramDataEntry[] = []

          entries
            .filter(entry => entry.value)
            .forEach(entry => {

              if (!topics.some(x => x.id === entry.topicId)) {
                topics.push(entry.topic);
              }
            });

          const diagramDateFormat = 'ddd DD.MM.'

          diagramData.push({
            name: '-',
            color: 'transparent',
            series: timeFrame.days.map(day => ({
              name: dayjs(day).format(diagramDateFormat),
              value: 0,
            }))
          } as any)

          topics.forEach(topic => {
            diagramData.push({
              topicId: topic.id,
              name: topic.name,
              color: topic.color,
              series: entries
                .filter(entry => entry.topicId === topic.id && entry.value)
                .map(entry => ({
                  name: dayjs(entry.date).format(diagramDateFormat),
                  value: entry.value! * 10
                }))
            })
          });

          const topicColors = topics.map(x => ({name: x.name, value: x.color}))

          return {
            data: diagramData,
            customColors: [
              ...topicColors,
              {name: '-', value: 'transparent'}
            ]
          };
        })
      )
  }

  getEntriesByDate$(date: string): Observable<Dc3Entry[]> {

    return entryStore.pipe(
      selectManyByPredicate(x => !x.removed && dayjs(x.date).isSame(date, 'day')),
      addTopics,
      sortByTopicOrder
    )
  }

  getEntriesByTopic$(topic: Dc3Topic): Observable<Dc3Entry[]> {
    return entryStore.pipe(
      selectManyByPredicate(x => !x.removed && x.topicId === topic.id),
      map(x => x.map(entry => ({...entry, topic})))
    );
  }


  upsertEntries(entries: Dc3Entry[]) {
    const updateEntries = entries.map(entry => {
      const updateEntry: Partial<Dc3Entry> = {...entry, synced: false};
      delete updateEntry.topic;
      return updateEntry;
    });
    entryStore.update(
      upsertEntities(updateEntries)
    )
  }

  deleteEntries(entries: Dc3Entry[]) {
    const deleteEntries =
      entries.map(entry => ({...entry, removed: true}));
    this.upsertEntries(deleteEntries)
  }

  //danger this will blow bundle size... create seperate tool for this
  // setMockData() {
  //   const mock = buildMock();
  //   console.log(mock);
  //   this.topicRepository.upsertTopics(mock.topics);
  //   this.upsertEntries(mock.entries);
  // }
  reset() {
    entryStore.reset();
  }
}
