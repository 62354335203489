<div class=" p-0">
  <ion-row *ngIf="view && timeFrame" class="ion-align-items-center">

    <ion-col size="auto">
      <ion-button
        fill="clear"
        mwlCalendarPreviousView
        [view]="view"
        [(viewDate)]="timeFrame.date"
        (viewDateChange)="timeFrameChange.next(timeFrame)">
        <ion-icon slot="icon-only" name="arrow-back-circle-outline"></ion-icon>
      </ion-button>

    </ion-col>
    <ion-col size="auto">
      <h3 class="m-0">{{ timeFrame.date | calendarDate: view + 'ViewTitle':locale : 1 }}</h3>
    </ion-col>

    <ion-col size="auto">

      <ion-button [fill]="todayIsInTimeFrame ? 'solid' : 'outline'"
                  size="small"
                  mwlCalendarToday
                  [(viewDate)]="timeFrame.date"
                  (viewDateChange)="timeFrameChange.next(timeFrame)">
        Diese Woche
      </ion-button>
      <ion-button
        fill="clear"
        mwlCalendarNextView
        [view]="view"
        [(viewDate)]="timeFrame.date"
        (viewDateChange)="timeFrameChange.next(timeFrame)">
        <ion-icon slot="icon-only" name="arrow-forward-circle-outline"></ion-icon>
      </ion-button>
    </ion-col>
  </ion-row>
</div>


<!--  <ion-col size-md="4">-->
<!--      <ion-button-->
<!--        class="btn btn-primary"-->
<!--        (click)="viewChange.emit(CalendarView.Month)"-->
<!--        [class.active]="view === CalendarView.Month">-->
<!--        Month-->
<!--      </ion-button>-->
<!--      <ion-button-->
<!--        class="btn btn-primary"-->
<!--        (click)="viewChange.emit(CalendarView.Week)"-->
<!--        [class.active]="view === CalendarView.Week">-->
<!--        Week-->
<!--      </ion-button>-->
<!--      <ion-button-->
<!--        class="btn btn-primary"-->
<!--        (click)="viewChange.emit(CalendarView.Day)"-->
<!--        [class.active]="view === CalendarView.Day">-->
<!--        Day-->
<!--      </ion-button>-->
<!--  </ion-col>-->
