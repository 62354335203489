import {Component} from '@angular/core';
import {FirebaseSyncService} from '../data/firebase-sync.service';
import {FirebaseImageSyncService} from '../data/images/firebase-image-sync.service';
import {Title} from '@angular/platform-browser';
import {environment} from '../environments/environment';
import {imageDb} from '../data/images/image-db';
import {MarkdownService} from 'ngx-markdown';
import {ImageViewerComponent} from './image-viewer/image-viewer.component';
import {ModalController} from '@ionic/angular';
import {windowDebug} from '../tools/debug';
import {TopicRepository} from '../data/topic/topic.repository';
import {IntroComponent} from './intro/intro.component';
import {Auth, user} from '@angular/fire/auth';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  appVersion?: string;

  constructor(private firebaseSyncService: FirebaseSyncService,
              private firebaseImageSyncService: FirebaseImageSyncService,
              private auth: Auth,
              private title: Title,
              private modalController: ModalController,
              private markdownService: MarkdownService,
              private topicRepository: TopicRepository) {

    this.fetchVersion();

    windowDebug('AppComponent', this);

    (window as any)['appeComponente'] = this;

    if (!environment.production) {
      title.setTitle('dc3 DEV')
    }
  }


  ngOnInit() {
    this.setUpMarkdownImageRenderer();

    user(this.auth).subscribe(currentUser => {
      if (currentUser) {
        this.firebaseSyncService.topicsExist().then(exists => {
          if (!exists) {
            this.showIntroModal();
          }
        })
      }
    })
  }

  fetchVersion() {
    fetch('./assets/version.json')
      .then(res => res.json()
        .then(data => this.appVersion = data.version));

  }

  setUpMarkdownImageRenderer() {
    this.markdownService.renderer.image = (href, title, text) => {
      // console.log({href, title, text})
      if (href) {
        imageDb.images.get(href).then(async imageEntry => {
          if (!imageEntry) {
            imageEntry = await this.firebaseImageSyncService.downloadImage(href);
          }

          if (imageEntry) {
            const imgEl = document.getElementsByClassName(`img-${href}`)[0] as HTMLImageElement;
            // console.log({imgEl})
            imgEl.src = URL.createObjectURL(imageEntry.data);
            imgEl.onclick = () => this.openImageViewer(imageEntry);
          }
        })
        return `<img class="entry-image img-${href}">`
      }
      return '';
    }
  }

  openImageViewer(imageEntry: any) {
    this.modalController.create({
      component: ImageViewerComponent,
      componentProps: {imageEntry},
      cssClass: 'image-viewer'
    }).then(modal => modal.present());
  }

  private showIntroModal() {
    this.modalController.create({
      component: IntroComponent
    }).then(modal => modal.present());
  }

  // async testImage(ev: any) {
  //   let file = ev.target.files[0] as File;
  //   if (!file) {
  //     return;
  //   }
  //   this.webWorker = new Worker('assets/scripts/background-heic-converter.js');
  //   this.webWorker.onmessage = (event) => {
  //     console.log('converted heic!', event);
  //     if (event.data === 'heic-converted') {
  //       console.log('heic converted')
  //     }
  //   }
  //
  //   const arrayBuffer = await file.arrayBuffer()
  //
  //   this.webWorker.postMessage('start-convert', [arrayBuffer])
  //
  // }
}
