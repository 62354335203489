import {inject, Injectable} from '@angular/core';
import {
  collection,
  collectionChanges,
  doc,
  Firestore, getDoc, getDocs, limit, orderBy,
  query,
  writeBatch
} from '@angular/fire/firestore';
import {Auth, authState} from '@angular/fire/auth';
import {
  combineLatest, debounce, debounceTime,
  filter,
  map, Observable,
  startWith,
  Subscription,
  switchMap
} from 'rxjs';
import {OnlineStatusService, OnlineStatusType} from 'ngx-online-status';
import {Store} from '@ngneat/elf';
import {deleteEntities, selectManyByPredicate, upsertEntities} from '@ngneat/elf-entities';

import {QueryConstraint} from '@firebase/firestore';
import {importantLog} from '../tools/debug';
import {environment} from '../environments/environment';
import {Dc3Topic} from './topic/topic';

// maybe something like this will be required for more complex setups
export interface FirebaseSyncConfig {
  store: Store;
  toSync$?: Observable<any[]>;
  fetchQueryConstraints$?: Observable<QueryConstraint[]>
}

const debugPath = 'null';

@Injectable({
  providedIn: 'root'
})
export class FirebaseSyncService {
  private onlineStatusService = inject(OnlineStatusService);
  private auth = inject(Auth);
  private authState$ = authState(this.auth);

  private firestore = inject(Firestore);


  collectionPath(entityPath: string) {
    return `users/${this.auth.currentUser?.uid}/${entityPath}`
  }

  isOnlineAndLoggedIn$ = combineLatest([
    this.onlineStatusService.status.pipe(startWith(this.onlineStatusService.getStatus())),
    this.authState$
  ]).pipe(
    map(([onlineStatus, authState]) => {
      return onlineStatus === OnlineStatusType.ONLINE && !!authState
    }),
    filter(isOnlineAndLoggedIn => isOnlineAndLoggedIn)
  )

  private fetchSubscriptions = new Subscription();
  private syncSubscriptions = new Subscription();
  private collectionChangesSubscriptions: { [name: string]: Subscription } = {}


  constructor() {
    (window as any)['firebaseSyncService'] = this;
  }

  collectionRef(entityPath: string) {
    return collection(
      this.firestore,
      this.collectionPath(entityPath)
    );
  }

  registerStore(config: FirebaseSyncConfig) {
    const store = config.store;
    const fetchTimeFrame$ = config.fetchQueryConstraints$;
    const toSync$ = config.toSync$ || store.pipe(selectManyByPredicate(x => !x.synced))

    const upsertFunction = (entities: any[]) => {
      const removed = entities.filter(x => x.removed)
      const updated = entities.filter(x => !x.removed);

      if (store.name === debugPath) {
        importantLog('removing', removed.length);
        importantLog('adding', updated.length);
      }


      store.update(
        deleteEntities(removed.map(x => x.id)),
        upsertEntities(updated.map(x => ({...x, synced: true})))
      );
    }
    const entityPath = store.name;


    if (fetchTimeFrame$) {
      this.isOnlineAndLoggedIn$.pipe(
        filter(isOnlineAndLoggedIn => isOnlineAndLoggedIn),
        switchMap(isOnlineAndLoggedIn => fetchTimeFrame$)
      ).subscribe(queryConstraints => {
        if (this.collectionChangesSubscriptions[store.name] && !this.collectionChangesSubscriptions[store.name].closed) {
          this.collectionChangesSubscriptions[store.name].unsubscribe();
        }
        this.collectionChangesSubscriptions[store.name] = this.fetch(
          entityPath,
          upsertFunction,
          queryConstraints
        );
      })
    } else {
      this.fetchSubscriptions.add(
        this.isOnlineAndLoggedIn$.pipe(
          filter(isOnlineAndLoggedIn => isOnlineAndLoggedIn)
          // take(1)
        ).subscribe(() => {
          if (!this.collectionChangesSubscriptions[store.name] || this.collectionChangesSubscriptions[store.name].closed) {
            this.collectionChangesSubscriptions[store.name] = this.fetch(entityPath, upsertFunction);
          }
        })
      );
    }


    this.syncSubscriptions.add(
      combineLatest([
        this.isOnlineAndLoggedIn$,
        toSync$.pipe(debounceTime(environment.syncUpDebounceMilliseconds))
      ]).pipe(map(([isOnlineAndLoggedIn, toSync]) => {
        if (isOnlineAndLoggedIn) {
          this.syncUp(entityPath, toSync)
        }
      })).subscribe()
    );
  }


  reset() {
    for (let storeName of Object.keys(this.collectionChangesSubscriptions)) {
      this.collectionChangesSubscriptions[storeName].unsubscribe();
    }
  }


  getDocumentSnapshotFor(entityPath: string, id: string) {
    const d = doc(
      this.firestore,
      this.collectionPath(entityPath),
      id
    );
    return getDoc(d);
  }


  private fetch(entityPath: string,
                upsertFunction: Function,
                queryConstraints?: QueryConstraint[]) {
    if (entityPath === debugPath) {
      console.log('FETCH', entityPath, queryConstraints);
    }

    const collectionRef = collection(
      this.firestore,
      this.collectionPath(entityPath)
    );
    let q = query(collectionRef);
    if (queryConstraints) {
      q = query(collectionRef, ...queryConstraints);
    }

    getDocs(q).then(docs => docs.docs);

    return collectionChanges(q)
      .subscribe(documentChanges => {
        if (entityPath === debugPath) {
          console.log('changes', entityPath, queryConstraints,
            documentChanges.map(x => x.type),
            documentChanges.map(x => x.doc.data()));
        }

        const changes = documentChanges
          .filter(x => x.type !== 'removed')
          .map(x => x.doc.data());
        upsertFunction(changes);
      });
  }

  private syncUp(entityPath: string, entities: any[]) {
    if (entities.length > 0) {

      // leave this here for quickly analyzing firestore data quota
      importantLog('syncUp', entityPath, entities.length);

      const batch = writeBatch(this.firestore);
      entities.forEach(entity => {
        const entityRef = doc(
          this.firestore,
          this.collectionPath(entityPath),
          entity.id
        );
        if (entity.removed) {
          batch.delete(entityRef);
        } else {
          batch.set(entityRef, {...entity, synced: true});
        }
      });
      batch.commit()
        .catch(err => {
          console.error('Sync failed', entityPath, err);
        })
        .then(() => {
          // console.log('Synced', entityPath);
        })
    }
  }


  async topicsExist() {
    const ref = this.collectionRef('topics');
    const q = query(ref, limit(1));
    const docsExist = await getDocs(q)
      .then(docs => docs.docs.length);
    return Boolean(docsExist)
  }


  async getHighestTopicOrder() {
    const ref = this.collectionRef('topics');
    const q = query(
      ref,
      orderBy('order', 'desc'),
      limit(1)
    )
    const docSnaps = await getDocs(q)
      .then(docs => docs.docs);
    const topic = docSnaps
      .map(docSnap => docSnap.data())[0] as Dc3Topic;
    return topic.order;
  }
}
