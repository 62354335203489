import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {DayComponent} from './day/day.component';
import dayjs from 'dayjs';
import {dateFormat} from '../tools/date';
import {SettingsComponent} from './settings/settings.component';
import {DevComponent} from './dev/dev.component';
import {LoginComponent} from './login/login.component';
import {canActivate, redirectLoggedInTo, redirectUnauthorizedTo} from '@angular/fire/auth-guard';
import {RegisterComponent} from './register/register.component';
import {LandingComponent} from './landing/landing.component';
import {landingAuthGuard} from './landing/landing.auth-guard';

const authGuard = canActivate(() => redirectUnauthorizedTo('login'))


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: LandingComponent,
    ...canActivate(() => redirectLoggedInTo('today'))
  },
  {
    path: 'landing',
    component: LandingComponent
  },
  {
    path: 'register/:key',
    component: RegisterComponent,
    ...canActivate(() => redirectLoggedInTo('today'))
  },
  {
    path: 'login',
    component: LoginComponent,
    ...canActivate(() => redirectLoggedInTo('today'))
  },
  {
    path: 'understand',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    ...authGuard
  },
  {
    path: 'topics',
    loadChildren: () => import('./topic/topic.module').then(m => m.TopicModule),
    ...authGuard
  },
  {
    path: 'today',
    component: DayComponent,
    ...authGuard
  },
  {
    path: 'day/:date',
    component: DayComponent,
    ...authGuard
  },
  {
    path: 'plan',
    loadChildren: () => import('./calendar/calendar.module').then(m => m.CalendarModule),
    ...authGuard
  },
  {
    path: 'settings',
    component: SettingsComponent,
    ...authGuard
  },
  {
    path: 'dev',
    component: DevComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
