import {v4 as uuidv4} from 'uuid';
import * as dayjs from 'dayjs'

import {dateFormat} from '../../tools/date';
import {Dc3Topic} from '../topic/topic';

export interface Dc3Entry {
  id: string;
  topicId: string;
  date: number;
  value: number | null;
  content: string | null;
  isFullEntry: boolean;

  topic: Dc3Topic;

  synced: boolean;
  removed: boolean;
}

export const createEntry = (
  topic: Dc3Topic,
  isFullEntry = true,
  date = dayjs().format(dateFormat)
): Dc3Entry => {

  const dateInstance = dayjs(date);

  let absoluteDate = dateInstance.endOf('day').valueOf();

  if (dateInstance.isToday()) {
    absoluteDate = new Date().valueOf();
  }

  return {
    id: uuidv4(),
    topicId: topic.id,
    date: absoluteDate,
    value: null,
    content: null,
    isFullEntry,
    topic,
    synced: false,
    removed: false
  }
}

export const isEmptyEntry = (x: Dc3Entry) =>
  x.value === null
  && (x.content === null || x.content === '');


export interface Dc3DiagramViewModel {
  data: Dc3DiagramDataEntry[];
  customColors: { name: string, value: string }[]
}

export interface Dc3DiagramDataEntry {
  topicId: string;
  name: string;
  color: string;
  series: {
    name: string;
    value: number;
  }[]
}
