<div *ngIf="indexBlock && indexBlock.entries.length"
     class="simple-card mb-20">
  <div class="mb-10">
    <ion-badge color="light">
      <ion-icon class="mr-5" [name]="indexBlock.icon"></ion-icon>
      {{indexBlock.title}}
    </ion-badge>
  </div>

  <div>
    <ion-reorder-group [disabled]="false"
                       (ionItemReorder)="handleReorder($any($event))">

      <div *ngFor="let entry of indexBlock.entries"
         class="reorder-container py-5" style="display: flex; align-items: center;">

        <ion-reorder class="custom-reorder">
          <svg class="custom-reorder-icon" xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="white">
            <circle cx="1" cy="1" r="1" />
            <circle cx="1" cy="5" r="1" />
            <circle cx="1" cy="9" r="1" />
            <circle cx="5" cy="1" r="1" />
            <circle cx="5" cy="5" r="1" />
            <circle cx="5" cy="9" r="1" />
          </svg>
        </ion-reorder>
        <app-styled-topic [topic]="entry.topic"
                          class="topic-button"
                          [class.topic-outline]="!entry.isFullEntry"
                          [class.topic-active2]="entry.topicId === focusedTopicId"
                          (click)="topicClick.emit(entry.topicId)">
        </app-styled-topic>
<!--        <div>{{entry.topic.order}}</div>-->
    </div>

    </ion-reorder-group>
  </div>
</div>
