import {Component, OnInit} from '@angular/core';
import {imageDb} from '../../data/images/image-db';
import {liveQuery} from 'dexie';
import {from, tap} from 'rxjs';

@Component({
  selector: 'app-dev',
  templateUrl: './dev.component.html',
  styleUrls: ['./dev.component.scss']
})
export class DevComponent implements OnInit {
  catImages$ = from(liveQuery(() => imageDb.images.toArray()))
    .pipe(tap(x => console.log(x)));

  constructor() {
    (window as any)['devComponent'] = this;
  }

  ngOnInit() {
  }

  testError() {
    const testObj: any = {};
    testObj.blob();
    console.log(testObj.blob.dobbo);
  }

  onImageChange(ev: any) {


    const file = ev.target.files[0];

    imageDb.storeImage('test', file).then(res => console.log(res));

    //
    // var reader = new FileReader();
    // reader.addEventListener('load', (e: any) => {
    //   {
    //     const blob = new Blob([new Uint8Array(e.target.result)], {type: file.type});
    //     console.log(blob);
    //     // imageDb.storeImage(blob);
    //   }
    // });
    // reader.readAsArrayBuffer(file);

    console.log(ev);
    // var fileReader = new FileReader();
    // fileReader.onloadend = function (e) {
    //   var arrayBuffer = e.target.result;
    //   var fileType = $('#file-type').value;
    //   var blob = blobUtil.arrayBufferToBlob(arrayBuffer, fileType)
    //   console.log('here is a blob', blob);
    //   console.log('its size is', blob.size);
    //   console.log('its type is', blob.type);
    // };
    // fileReader.readAsArrayBuffer(file);
  }
}
