import {createStore, select} from '@ngneat/elf';
import {
  getEntity,
  selectEntity,
  selectMany,
  selectManyByPredicate,
  updateAllEntities,
  upsertEntities,
  withEntities
} from '@ngneat/elf-entities';
import {Injectable} from '@angular/core';
import {map} from 'rxjs';

import {createTopic, Dc3Topic} from './topic';
import {FirebaseSyncService} from '../firebase-sync.service';
import {windowDebug} from '../../tools/debug';
import {appPersistState} from '../persistence';

export const topicStore = createStore(
  {name: 'topics'},
  withEntities<Dc3Topic>()
);

appPersistState(topicStore);

export const topicsObj$ = topicStore.pipe(
  select(store => store.entities)
);

@Injectable({providedIn: 'root'})
export class TopicRepository {
  getTopic(topicId: string) {
    return topicStore.query(getEntity(topicId));
  }

  getTopic$(topicId: string) {
    return topicStore.pipe(selectEntity(topicId));
  }

  getTopics$(topicIds: string[]) {
    return topicStore.pipe(selectMany(topicIds));

  }

  topics$ = topicStore.pipe(selectManyByPredicate(x => !x.removed));

  // topicsObj$ = topicStore.pipe(
  //   select(store => store.entities)
  // );

  trackerTopics$ = topicStore.pipe(
    selectManyByPredicate(x => !x.removed && !!x.tracker?.range),
    map(topics => {
      topics.sort((a, b) => {
        const valA = a.order !== undefined ? a.order : 9999;
        const valB = b.order !== undefined ? b.order : 9999;
        return valA - valB
      });
      return topics;
    })
  );

  pinnedTopics$ = topicStore.pipe(
    selectManyByPredicate(x => !x.removed && !!x.isPinned)
  );

  standardTopics$ = topicStore.pipe(
    selectManyByPredicate(x => !x.removed && !x.tracker?.range)
  )

  constructor(private firebaseSyncService: FirebaseSyncService) {
    windowDebug('TopicRepo', this);
    this.firebaseSyncService.registerStore({store: topicStore});
  }

  async createTopic(name: string) {
    const highestTopicOrder = await this.firebaseSyncService.getHighestTopicOrder();
    return createTopic(name, highestTopicOrder ? highestTopicOrder + 1 : 0);
  }

  getTopicsObj() {
    return topicStore.getValue().entities;
  }

  upsertTopics(topics: Dc3Topic[]) {
    const updateTopics = topics.map(
      topic => ({...topic, synced: false})
    );
    topicStore.update(upsertEntities(updateTopics));
  }

  deleteTopics(topics: Dc3Topic[]) {
    const deleteTopics =
      topics.map(topic => ({...topic, removed: true}))
    this.upsertTopics(deleteTopics);
  }

  resync() {
    topicStore.update(updateAllEntities(x => ({...x, synced: false})))
  }

  reset() {
    topicStore.reset();
  }
}
