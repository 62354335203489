export const rangeTrackerColor = (
  target: 'HIGH' | 'LOW' | undefined,
  value: number | null
) => {

  let color = 'primary';

  if (!value) {
    return color;
  }


  if (target === 'HIGH') {
    color = 'danger';
    if (value > 0.15) {
      color = 'warning';
    }
    if (value > 0.6) {
      color = 'success';
    }
  } else if (target === 'LOW') {
    color = 'danger';
    if (value < 0.8) {
      color = 'warning';
    }
    if (value < 0.5) {
      color = 'success';
    }
  }
  return color;

}
