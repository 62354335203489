import {environment} from '../environments/environment';

export const windowDebug = (name: string, component: any) => {
  if (!environment.production) {
    (window as any)[name] = component;
  }
}

export const logValue = (value: any, name: string = '') => {
  if(!environment.production) {
    console.log(name, value);
  }
  return value;
}

export const importantLog = (text: string, ...params: any) => {
  console.log(`%c${text}`, 'background: #bada55; color: #222; padding: 5px', ...params);
}
