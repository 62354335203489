import {v4 as uuidv4} from 'uuid';
import {getRandomTopicColor} from '../../tools/colors';

export interface Dc3Topic {
  id: string;
  order?: number;
  name: string;
  color: string;
  isPinned?: boolean;
  tracker?: {
    range: boolean;
    rangeTarget: 'HIGH' | 'LOW'
  },
  synced: boolean;
  removed: boolean;
  counter?: {
    values: number;
    content: number
  }
}

export const createTopic = (name: string, order: number): Dc3Topic => ({
  id: uuidv4(),
  name,
  color: getRandomTopicColor(),
  order,
  synced: false,
  removed: false
});
