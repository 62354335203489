<ion-header>
  <ion-toolbar>
    <ion-title class="text-header">
      Willkommen
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding" #content>

  <ng-container *ngIf="step === 'start'">
    <h2>Dein Start bei dc3</h2>
    <p>
      Anscheinend ist dies das erste mal in der App. Falls du nicht weißt worum es geht, dies ist ein Moodtracker und
      Tagebuch in einem. Du kannst Themen anlegen und bestimmten welche du täglich bewerten möchtest, aber auch andere
      Erfolge und Erlebnisse notieren.
    </p>
    <p>
      Die App ist genau darauf ausgerichtet, dass du dich täglich mit damit auseinandersetzt was los ist. Dabei lässt
      sie dir den vollen Freiraum ob du ganz viel los werden möchtest oder nur schnell deine Gefühle bewerten möchtest.
    </p>

  </ng-container>

  <ng-container *ngIf="step === 'topicInfo'">
    <h2>Themen</h2>
    <p>
      Um dc3 Nutzen zu können, musst du erst ein paar Themen anlegen und einige davon als Tracker deklarieren. Themen
      können wirklich alles sein, was dich in deinem Alltag beschäftigt - von Gefühlen, über Arbeit, Freizeit bis zu
      Sozialen Erfahrungen.
    </p>
    <p>
      Du kannst sie nutzen um deine Fortschritte zu dokumentieren und Erinnerungen festzuhalten, vielleicht auch zum
      Schreiben selbst. Es empfiehlt sich ein allgemeines Thema “Tagebuch” oder “Gedanken” zu erstellen, bei dem du
      einfach drauf lostippen kannst, wenn du gerade kein klares Thema im Kopf hast.
    </p>
    <p>
      Tracker sind darauf ausgelegt, mehr Achtsamkeit auf die grundlegenden Dinge des Lebens zu richten. Dann kannst du
      auch definieren, ob das ziel ein hoher oder ein niedriger Wert sein soll.
    </p>
  </ng-container>

  <ng-container *ngIf="step === 'tracker'">
    <h2>Tracker festlegen ({{selectedTracker.length}}/6)</h2>
    <p>
      Hier sind einige Vorschläge für deinen Start. Du kannst jederzeit neue Themen erstellen, sie ändern und nach
      deinen Bedürfnissen anpassen. Wähle mindestens zwei aus:
    </p>
    <ion-row>
      <ion-col *ngFor="let tracker of startTracker" size="6">
        <ion-checkbox labelPlacement="end"
                      [disabled]="selectedTracker.length === 6 && !trackerIsSelected(tracker)"
                      [value]="tracker"
                      (ionChange)="selectTracker($event)">
          {{tracker.name}}
        </ion-checkbox>
      </ion-col>
    </ion-row>
  </ng-container>

  <ng-container *ngIf="step === 'topics'">
    <h2>Themen festlegen</h2>
    <p>
      Hier sind einige Vorschläge für deinen Start. Du kannst jederzeit neue Themen erstellen, sie ändern und nach
      deinen Bedürfnissen anpassen.
    </p>
    <ion-row>
      <ion-col *ngFor="let topicName of startTopics" size="6">
        <ion-checkbox labelPlacement="end"
                      [value]="topicName"
                      (ionChange)="selectTopic(topicName)">
          {{topicName}}
        </ion-checkbox>
      </ion-col>
    </ion-row>

  </ng-container>


  <ng-container *ngIf="step === 'info'">
    <h2>Kleine Anleitung</h2>
    <p>
      Jetzt bist du eigentlich startklar! Hier noch ein paar grundlegende Informationen zur verwendung der App:
    </p>

    <h3>Thema bearbeiten</h3>
    Du kannst jederzeit und überall in der App auf ein Thema rechts klicken um es zu bearbeiten. Die Reihenfolge kannst
    du momentan in der 'mein tag' Ansicht ändern, indem du über ein Thema gehst, an den sechs punkten link packst und
    sie verschiebst.

    <h3>mein tag</h3>
    <p>
      Hier spielt sich die Magie ab: Fülle täglich deine Tracker auf und schreibe auf, was dir sonst noch so einfällt.
      Sobald du ein Thema einmal angelegt hast, wird es dir immer wieder vorgeschlagen.
    </p>
    <p>
      Besonders wichtige Themen, kannst du mit einem Lesezeichen versehen, damit sie dir jeden Tag angelegt angezeigt
      werden.
    </p>

    <h3>planen</h3>
    <p>
      Mit dem Kalender kannst du deine Woche planen, indem du Themen per Drag&Drop darauf ablegst. Du kannst auch durch
      einen Klick in den Kalender neue Einträge erstellen, oder bestehende Einträge durch einen Klick darauf bearbeiten
      oder Löschen.
    </p>
    <p>
      Deine Kalendereinträge werden dir am entsprechenden Tag ganz oben in 'mein tag', zusammen mit der Beschreibung die
      du vielleicht eingegeben hast angezeigt.
    </p>

    <h3>verstehen</h3>
    <p>
      Wenn du die App schon ein paar Tage benutzt hast, möchtest du vielleicht wissen wie sich deine Werte entwickelt
      haben. Unter 'verstehen' bekommst du Wochendurchschnitte und die Veränderung zu Woche davor.
    </p>
    <p>
      Darüber hinaus findest du ein interaktives Diagram und die einzelnen Wochentage, was dir dabei helfen kann, zu
      verstehen, warum du dich so fühlst wie du dich fühlst.
    </p>

    <h3>blog</h3>
    <p>Hier geht es um deine Inhalte. Du möchtest deine kreativen Erfolge nachvollziehen? Dich daran erinnern was du
      letzte Woche fotografiert hast? Deine Fortschritte mit jemandem teilen, ohne ins Stottern zu geraten?
    </p>
    <p>
      Dafür gibt es den
      Blog! Lass dir alle Einträge von einem Thema oder von mehreren Themen gleichzeitig anzeigen. Quasi dein
      persönliches Twitter, ohne den Bullshit. Und ohne die komischen Leute. <3
    </p>
  </ng-container>

</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="modalController.dismiss()">
        überspringen
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">

      <ion-button *ngIf="step === 'start'"
                  (click)="step = 'topicInfo'"
                  color="primary"
                  fill="solid">
        Loslegen!
      </ion-button>

      <ion-button *ngIf="step === 'topicInfo'"
                  (click)="step = 'tracker'"
                  color="primary"
                  fill="solid">
        Tracker auswählen
      </ion-button>

      <ion-button *ngIf="step === 'tracker'"
                  (click)="step = 'topics'; "
                  [disabled]="selectedTracker.length < 2"
                  color="primary"
                  fill="solid">
        Sonstige Themen auswählen
      </ion-button>


      <ion-button *ngIf="step === 'topics'"
                  (click)="showInfo()"
                  color="primary"
                  fill="solid">
        Weiter
      </ion-button>

      <ion-button *ngIf="step === 'info'"
                  (click)="start()"
                  color="primary"
                  fill="solid">
        Los gehts!
      </ion-button>


    </ion-buttons>
  </ion-toolbar>
  <!--  <ion-row class="ion-justify-content-end">-->
  <!--    <ion-col size="auto">-->
  <!--      <ion-button (click)="step = 'topicInfo'">-->
  <!--        Loslegen!-->
  <!--      </ion-button>-->
  <!--    </ion-col>-->
  <!--  </ion-row>-->
</ion-footer>
