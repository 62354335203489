import {createStore} from '@ngneat/elf';
import {getEntity, selectManyByPredicate, updateAllEntities, upsertEntities, withEntities} from '@ngneat/elf-entities';
import {Dc3Planning} from './planning';
import {Injectable} from '@angular/core';
import dayjs from 'dayjs';
import {dateFormat, TimeFrame} from '../../tools/date';
import {FirebaseSyncService} from '../firebase-sync.service';
import {ReplaySubject} from 'rxjs';
import {windowDebug} from '../../tools/debug';
import {QueryFieldFilterConstraint} from '@firebase/firestore';
import {appPersistState} from '../persistence';

const planningStore = createStore(
  {name: 'plannings'},
  withEntities<Dc3Planning>()
);

appPersistState(planningStore);

@Injectable({providedIn: 'root'})
export class PlanningRepository {
  private fetchFilterConstraints$ = new ReplaySubject<QueryFieldFilterConstraint[]>(1);

  plannings$ = planningStore.pipe(selectManyByPredicate(x => !x.removed));

  constructor(private firebaseSyncService: FirebaseSyncService) {
    this.firebaseSyncService.registerStore({
      store: planningStore,
      fetchQueryConstraints$: this.fetchFilterConstraints$.asObservable()
    });
    windowDebug('PlanningRepository', this);
  }

  setFetchTimeFrame(timeFrame: TimeFrame) {
    this.fetchFilterConstraints$.next(timeFrame.queryConstraints('startDate'));
  }

  planningsForDay$(date: string) {
    return planningStore.pipe(selectManyByPredicate(
      x => !x.removed && dayjs(x.startDate).isSame(date, 'day')
    ));
  }

  planningsByTimeFrame$(timeFrame: TimeFrame) {
    return planningStore.pipe(
      selectManyByPredicate(x =>
        !x.removed
        && timeFrame.isBetween(x.startDate)
      )
      // tap(x => console.log('checktopics', x)),
    );
  }

  get(planningId: string) {
    return planningStore.query(getEntity(planningId));
  }

  upsert(plannings: Dc3Planning[]) {
    const updateTopics = plannings.map(
      (planning): Dc3Planning => ({
        ...planning,
        synced: false
      })
    );
    planningStore.update(upsertEntities(updateTopics));
  }

  delete(plannings: Dc3Planning[]) {
    const deleteTopics =
      plannings.map(planning => ({...planning, removed: true}))
    this.upsert(deleteTopics);
  }

  migratePlanningsToDate() {
    planningStore.update(updateAllEntities(x => ({
      ...x,
      date: dayjs(x.startDate).format(dateFormat)
    })))
  }

  reset() {
    planningStore.reset();
  }
}
